global.jQuery = global.$ = require('jquery');
require('jquery-ui-browserify');
global.Tether = require("tether");
require("jquery-layout"); // http://layout.jquery-dev.com
global.showdown = require("showdown");
require("jquery-address");
require("qtip2");
global.gsap = require('gsap');
require("hammerjs");
global.svgPanZoom = require('svg-pan-zoom');
global.present = require("./js/present.js");
global.myLayoutObj = require('./js/idiagram-ui.js');
global.Handlebars = require('handlebars');
global.baseIdClass = require("./js/baseIdClass.js");
//global.mapDbApi = require("./js/mapDbApi.js");
global.idiagramUtil = require('./js/idiagram-util.js');
global.DrawSVGPlugin = require('../../DrawSVGPlugin.min.js');  // https://greensock.com/drawSVG
global.gsap.gsap.registerPlugin(DrawSVGPlugin);
global.gsap.gsap . config ({ 

    nullTargetWarn : false , 
 });   
global.idiagramSvg = require('./js/idiagram-svg.js');
require('svg.js');
global.localforage = require('localforage');

global.formBuilder = require('./js/form-builder.min.js');
global.formRender = require('./js/form-render.min.js');

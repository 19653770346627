/**
 * idiagram-util.js class for for new utilities. Initially set up to move standardized functions from customstuff.js
 */
/* global $, 
    TweenLite,   
    Window,  myLayoutObj, present, showdown,  MouseEvent, myClientSocket, Handlebars, mapDbApi,
    idiagramSvg */

// -------------------------------- H I G H L I G H T I N G --------------------------------
/**
 * The togglehlt functions accept a single UNO ID as a selector, e.g. hlt=unoA
 * Global durations and opacity variable used by all highlighting functions
 * Defaults are setup in idiagram-svg.js, and they can be changed with the sethlt command.
 * idiagramSvg.bw - for black/white - set the opacity layer to white (1) or black (0).
 */

/**
 * Setup the highlighting variables: duration, opacity, high or low light (1=white or 0=black)
 */
function sethlt(p) {
    let params = p.selector.split(",");

    idiagramSvg.hltDuration =   Number(params[0].trim());
    idiagramSvg.hltOpacity =    Number(params[1].trim());
    idiagramSvg.bw =            Number(params[2].trim());

    // If there is an existing dimming layer, remove it - so the new values can take effect
    let dimmingGroup = document.getElementById("dimmingLayer");
    if (dimmingGroup != null) {
        dimmingGroup.parentNode.removeChild(dimmingGroup);
    }

    //remove all these commands from url
    $.address.parameter("sethlt", "");
}


/**
 * hlt sets the UNO highlighing on - if it's already on, delete the current copy and make a new one
 * (in case you want to highlight the current open/closed state)
 */
function hlt(p) {
    let unoID = p.selector;
    let theMap = $("g.svg-pan-zoom_viewport")[0];

    // Add the dimming layer (if it's not already there) - first, so the highlighted UNO will come after / on-top
    createDimming();

    // Create the placeholder
    let uno1 = document.getElementById(unoID);
    if (uno1 != null) {
        let pNode = uno1.parentElement;
        let placeHolder = document.createElement("div");
        let insertedNode = pNode.insertBefore(placeHolder, uno1);
        insertedNode.id = unoID + "placeholder";

        // If this is the first UNO to be highlighted, else just bring it to the front without fading,
        let highlighted = theMap.querySelectorAll(".highlighted");
        if (highlighted.length === 0) {
            $("#" + unoID).appendTo(theMap);
            uno1.classList.add("highlighted");
        }
        else {
            // fade on the highlighted UNO nicely
            let start = 1.0 - idiagramSvg.hltOpacity;
            gsap.gsap.to("#" + unoID, 0, {
                opacity: start
            });
            /*
            TweenLite.to("#" + unoID, 0, {
                opacity: start
            });*/
            $("#" + unoID).appendTo(theMap);
            uno1.classList.add("highlighted");
            gsap.gsap.to("#" + unoID,  {
                duration: idiagramSvg.hltDuration,
                opacity: 1.0
            });
            /*TweenLite.to("#" + unoID, idiagramSvg.hltDuration, {
                opacity: 1.0
            });*/
        }
        return true;
    }
    return false;
}

// togglehlt toggles the UNOs highlighting on/off - WORKS ONLY WITH ONE SELECTOR / UNO
function togglehlt(p) {
    let unoID = p.selector;
    $.address.parameter("togglehlt", ""); //remove all these commands from url
    // If the UNO is already highlighted - because there's a placeholder for this UNO
    let unoCopy = document.getElementById(unoID + "placeholder");
    if (unoCopy != null) {
        unhlt(p);
        return false;
    }
    else{
        hlt(p);
        return true;
    }
}

function undoHlt() {
    let i;
    let uno1;
    let unoID;
    let theMap = $("g.svg-pan-zoom_viewport")[0];
    let placeHolder;

    let highlighted = theMap.querySelectorAll(".highlighted");
    for (i = 0; i < highlighted.length; i++) {
        unoID = highlighted[i].id;
        uno1 = highlighted[i];
        if (uno1 != null) {
            placeHolder = document.getElementById(unoID + "placeholder");
            if (placeHolder != null) {
                moveUNO(unoID, placeHolder);
            }
        }
    }
}

// unhlt unhighlights the specified UNOs - and fades off the dimming layer if the .highlight class is empty
function unhlt(p) {
    /**
     * If the UNO is highlighted fade it off and remove it.  If there are no other highlighted UNOs fade out and turn
     * off the dimming layer.
     * Create a highlight for each selector (UNO)
     */
    let i;
    let uno1;
    let unoID = p.selector;
    let theMap = $("g.svg-pan-zoom_viewport")[0];
    let placeHolder;
    let start;

    $.address.parameter("unhlt", ""); //remove all these commands from url

    if (unoID === "all") {
        let highlighted = theMap.querySelectorAll(".highlighted");
        for (i = 0; i < highlighted.length; i++) {
            unoID = highlighted[i].id;
            uno1 = highlighted[i];
            if (uno1 != null) {
                placeHolder = document.getElementById(unoID + "placeholder");
                if (placeHolder != null) {
                    // fade off the highlighted UNO nicely
                    start = 1.0 - idiagramSvg.hltOpacity;
                    gsap.gsap.to("#" + unoID,  {
                        duration: idiagramSvg.hltDuration,
                        opacity: start,
                        onCompleteParams: [unoID, placeHolder],
                        onComplete: moveUNO
                    });
                   /* TweenLite.to("#" + unoID, idiagramSvg.hltDuration, {
                        opacity: start,
                        onCompleteParams: [unoID, placeHolder],
                        onComplete: moveUNO
                    });*/
                }
            }
        }
        return;
    }

    // Remove the highlight for each selector (UNO)
    uno1 = document.getElementById(unoID);
    if (uno1 != null) {
        placeHolder = document.getElementById(unoID + "placeholder");
        if (placeHolder != null) {
            // fade off the highlighted UNO nicely
            start = 1.0 - idiagramSvg.hltOpacity;
            gsap.gsap.to("#" + unoID, {
                duration :  idiagramSvg.hltDuration,
                opacity: start,
                onCompleteParams: [unoID, placeHolder],
                onComplete: moveUNO
            });
            /*TweenLite.to("#" + unoID, idiagramSvg.hltDuration, {
                opacity: start,
                onCompleteParams: [unoID, placeHolder],
                onComplete: moveUNO
            });*/
        }
    }
}

function moveUNO(unoID, placeHolder) {
    let pNode = placeHolder.parentElement;
    let uno1 = document.getElementById(unoID);
    let theMap = $("g.svg-pan-zoom_viewport")[0];

    // move the UNO to before the placeholder, then remove the placeholder
    pNode.insertBefore(uno1, placeHolder);

    gsap.gsap.to("#" + unoID, {
        duration: 0,
        opacity: 1.0
    });
    /*TweenLite.to("#" + unoID, 0, {
        opacity: 1.0
    });*/

    uno1.classList.remove("highlighted");
    // this line of code allows a node to delete itself
    placeHolder.parentNode.removeChild(placeHolder);

    // If nothing else is highlighed, fade the dimming layer to 0 opacity, but do not remove it.
    let highlighted = theMap.querySelectorAll(".highlighted");
    if (highlighted.length === 0) {
        gsap.gsap.to("#dimmingLayer",{ 
            duration: idiagramSvg.hltDuration,
            opacity: 0
        });
        /*TweenLite.to("#dimmingLayer", idiagramSvg.hltDuration, {
            opacity: 0
        });*/
    }
}

function createDimming() {
    // Check to see if the dimming object exists
    if (document.getElementById("dimmingLayer") == null) {
        let theMap = $("g.svg-pan-zoom_viewport")[0];

        // whiteLayer was not initialized yet; we will do so now
        window.whiteLayer = document.createElementNS("http://www.w3.org/2000/svg", "g");

        // 'http://www.w3.org/2000/svg' allows JQuery to handle window svg namespace
        $(window.whiteLayer).attr({
            id: "dimmingLayer",
            "class": "dimmingLayer",
            style: "opacity: 0; pointer-events: none" //this will be tweened in below.
        });

        // Use the mandatory MapSize UNO to get the size for the dimming layer - which must be on to get the right size
        // Make the dimming rectangle 4X MapSize
        let q = idiagramSvg.getURLParameterList("+++&on=MapSize");
        idiagramSvg.processCommandsInURL(q);
        let mapSize = document.getElementById("MapSize");
        let svgRect = mapSize.getBBox();
        let x = svgRect.x - (2 * svgRect.width) + (svgRect.width / 2);
        let y = svgRect.y - (2 * svgRect.height) + (svgRect.height / 2);
        let w = 4 * svgRect.width;
        let h = 4 * svgRect.height;

        // Set the dimming layer to be white (default) or black.
        let fillcolor = "#FFFFFF";
        if (idiagramSvg.bw === 0) {
            fillcolor = "#000000";
        }

        let myRect = document.createElementNS("http://www.w3.org/2000/svg", "rect");
        $(myRect).attr({
            height: h,
            width: w,
            y: y,
            x: x,
            "stroke-width": "0",
            fill: fillcolor
        });

        $(window.whiteLayer).append(myRect);
        $(window.whiteLayer).appendTo(theMap);
    }

    // Finally tween it visible
    gsap.gsap.to("#dimmingLayer",  {
        duration: idiagramSvg.hltDuration,
        opacity: idiagramSvg.hltOpacity
    });
    /*TweenLite.to("#dimmingLayer", idiagramSvg.hltDuration, {
        opacity: idiagramSvg.hltOpacity
    });*/
}

/**
 * These functions are code that is moved from idiagram-svg.js to here to handle generators to handle asynchronous code
 * because of the database .then stuff. This is so we don't execute a loop until we are ready.
 */
let addClass = function(el, className) {
    $(el).addClass(className);
};

function getValue(someKey) {
    return new Promise((resolve, reject) => {
        idiagramSvg.masterForage.getItem(someKey).then((err, value) => {
            if (!err) {
                resolve(value);
            }
            else {
                resolve("");
            }

        });
    });

}

function getDatabaseCoRoutine() {
    this.result = null;
    this.field = null;
}

getDatabaseCoRoutine.prototype.getDatabase = function(thisId, key) {
    let scrubbedId = idiagramSvg.stripId(thisId);
    this.field = key;

    //TODO: sometime handle the merged db of master and override.
    let newKey = scrubbedId; //"db_" + idiagramSvg.designerPrefs.masterDB + "_" + scrubbedId;

    idiagramSvg.masterForage.getItem(newKey, (row) => {
        if (row !== undefined && row) {
            //let truncatedId = idiagramSvg.stripId(scrubbedId)
            if (row.id !== undefined && row.id === scrubbedId && row[this.field] !== undefined) {
                //callBack(err, row[field]);

                let classesFromDBForThisId = row[this.field];
                if (classesFromDBForThisId !== undefined && classesFromDBForThisId.length) {
                    $("#" + scrubbedId).addClass(classesFromDBForThisId);
                }
            }

            this.result = row;
        }
        else {
            this.result = "";
        }
        $(this).triggerHandler("getItemDone", [row]);


    }).catch(function(err) {
        console.error(err);
    });

};

function* getDb(thisId, field) {
    let truncatedId = idiagramSvg.stripId(thisId);

    //TODO: sometime handle the merged db of master and override.
    let newKey = "db_" + idiagramSvg.designerPrefs.masterDB + "_" + truncatedId;
    yield getValue(newKey)
        .then(function(value) {
            return value;
        });
}

/**
 * This is a function that will update a field in a row in the database on the server and also the local version in memory
 * Note be careful with case of the field name. If the field name is not found, it will be added to the row along with field value
 * @param id,  the id of the row in the map database
 * @param collection, the collection of the database, such as "alpha_master"
 * @param fieldName, this is the field name that will be updated
 * @param newFieldValue this is the new value
 * example: idiagramUtil.updateField("n0", "alpha_master", "label", "foo");
 **/
function updateField(id, collection, fieldName, newFieldValue) {

    let data = {
        id: id,
        collection: collection,
        fieldName: fieldName,
        newFieldValue: newFieldValue
    };

    $.post("/maps/updateField", data, function(result) {
        if (result.status === "success") {
            console.log("Map DB updated.");
            
            //Since we have success updating the DB on the Server, now update it on the local version in idiagramSvg.database
            let row = idiagramSvg.database.get(id);
            if (row !== undefined && row.id !== undefined && row[fieldName] !== undefined) {
                row[fieldName] = newFieldValue;
            }

        }
        else {
            console.error("Map DB update was unsuccessful: " + result.msg);
        }

    });

}

let idiagramUtil = {
    togglehlt: togglehlt,
    unhlt: unhlt,
    undoHlt: undoHlt,
    getDatabaseCoRoutine: getDatabaseCoRoutine,
    getDb: getDb,
    hlt: hlt,
    updateField: updateField
};
module.exports = idiagramUtil;

function _defineProperty(obj, key, value) { if (key in obj) { Object.defineProperty(obj, key, { value: value, enumerable: true, configurable: true, writable: true }); } else { obj[key] = value; } return obj; }

/**
 * idiagram-svg.js Logic for manipulating svg file for idiagram Created 2016, Larry A. Maddocks
 */
// version of this project
// greensock. Start using greensock more to pan and zoom to correct places.
/* global $,
    jQuery,gsap, svgPanZoom,  Tooltip,
    Window,  myLayoutObj, present, showdown,  MouseEvent, myClientSocket, Handlebars, mapDbApi,
    baseIdClass, svgFile, folder, zoomSensitivity ,defaultURL, Hammer,
    XMLSerializer, blob, URL, location, idiagramUtil,localforage */

var flagDelay = false;
var unoInfoAnterior = '';
var isRunning = false;

var flagDoNoResetMap = false;

var version = "v. 3.10"; //unoInfo teste
var idiagramUi = myLayoutObj;

// the panZoom object. Created here and referenced in idiagram-ui.js
var zoomTiger;
var zoomRatio = 1.0; // Added by Marshall Clemens - tracks zoom ratio for the current window size

var idGroupObject = {}; // idGroupObject is an object with key/value pairs that map id's to the group object


var lastEmbedSrc; // svg file found from global in html file or json file with same first name as html file.

// keep track of info pane that I loaded so don't load in twice in a row which messes up event maybe
var CurrentIdOfInfoPane = "";

/**
 * contains the url in the address bar. Get's set originally in svgloaded(). Used when a +++ is sent in so we
 * can add the paramets sent with +++ to end of parameter list of previous url.
 */
var PreviousUrlAddress;
/**
 * svgness: the current object that is being pointed to. Mouse unhover was returning this equal to root svg in
 * Safari found that I was trying to move objects back when tooltip came off and item, when mouseover tooltip
 * was never triggered
 */
var svgness;
var database;
// TODO: Document this:
var converter = new showdown.Converter();

// Panning == true while panning (really mountained +++sedown) and false when mouseup.
var Panning = false;
// Title of web site, to be used in history
var PageTitle = $('title').text();
var masterDatabase;
var overrideDatabase;
var masterForage = null; //local master db
var overrideForage = null;
var lastUpdatedDb = null;

//var delay = 100; // 100;
var TextHoverTimer;
var TextHoverDelay = 200;

// these two variables are for presentation navigation
var Slides;
var SlideSelected = -1;
//keeps track of the last story file loaded.
var LastStoryFile = null;
/*
l-click On an UNO: lock-on function for that UNO
i-click On an UNO: toggle highlight for that UNO - hlt=thisUNO
g-click On an UNO: zoom in on that UNO - gotoz=thisUNO
a-click Play the animation for that UNO - play=thisUNO
*/
var gClicked = null, //equals g if it was pressed right before a click
    elClicked = null;

var designerPrefs; //contains designer preferences for this svg map. This comes from a json file that has same name as the html file.
var tweenDuration = .4; //used in svg-pan-zoom for the tween duration
var traceTime = 1.0; // used in controlmenu.js
/**
 * Global durations and opacity variable used by all highlighting functions
 * Defaults set here, and they can be changed with the sethlt command - found in idiagram-util.js.
 * bw - for black/white - set the opacity layer to white (1) or black (0).
 */
var hltDuration = 1.0;
var hltOpacity = 0.80;
var bw = 1;
var mapEditorTemplate = null; //most recent handlebars map editor template loaded. If not null, then click causes editor to open
var doTweeningInSvgPanZoom = false; //Set this false when first initializing so svg-pan-zoom & other initialization is not so slow.

//IE: This will handle IE MouseEvent code so you can trigger a click on a link.
//TODO: In the future we might just make all code use the createEvent() and initMouseEvent code
if (typeof MouseEvent !== 'function') {
    (function () {
        var _MouseEvent = window.MouseEvent;
        window.MouseEvent = function (type, dict) {
            dict = dict | {};
            var event = document.createEvent('MouseEvents');
            event.initMouseEvent(
                type,
                (typeof dict.bubbles == 'undefined') ? true : !!dict.bubbles,
                (typeof dict.cancelable == 'undefined') ? false : !!dict.cancelable,
                dict.view || window,
                dict.detail | 0,
                dict.screenX | 0,
                dict.screenY | 0,
                dict.clientX | 0,
                dict.clientY | 0, !!dict.ctrlKey, !!dict.altKey, !!dict.shiftKey, !!dict.metaKey,
                dict.button | 0,
                dict.relatedTarget || null
            );
            return event;
        }
    })();
}

/**
 * svg: for the jquery svg library tools
 */
function svgloaded() {
    svgness = $("#container svg")[0];
    idiagramSvg.svgness = svgness; //expose this to the world
    $(svgness).attr({
        width: "100%",
        height: "100%",
        id: "svgness"
    });

    $(svgness).mousedown(function () {
        // addClass(svgness, "panning");
        Panning = true;
    });
    $(svgness).mouseup(function () {
        // removeClass(svgness, "panning");
        Panning = false;
    });

    reloadZoomTiger(); //reload zoomTiger from svg-pan-zoom.js
    idiagramSvg.tweenDuration = designerPrefs.hasOwnProperty("tweenDuration") ? designerPrefs.tweenDuration : .4; //used in svg-pan-zoom for the tween duration
    tweenDuration = idiagramSvg.tweenDuration; //used in svg-pan-zoom for the tween duration
    $('.svg-pan-zoom_viewport').css('transition','transform ease-out ' + tweenDuration +'s'); //change css for tweenduration
    idiagramSvg.traceTime = designerPrefs.hasOwnProperty("traceTime") ? designerPrefs.traceTime : 1.0; // used in controlmenu.js to set the trace duration
    traceTime = idiagramSvg.traceTime;
    idiagramSvg.hltDuration = designerPrefs.hasOwnProperty("hltDuration") ? designerPrefs.hltDuration : 1.0;
    hltDuration = idiagramSvg.hltDuration;
    idiagramSvg.hltOpacity = designerPrefs.hasOwnProperty("hltOpacity") ? designerPrefs.hltOpacity : 0.8;
    hltOpacity = idiagramSvg.hltOpacity;
    idiagramSvg.bw = designerPrefs.hasOwnProperty("bw") ? designerPrefs.bw : 1;
    bw = idiagramSvg.bw;

    /**
     * There is a g element that gets created by svgPanZoom that has a "transform"
     * attribute that messes me up. So this removes that. found I had to wait a
     * bit before it showed up, hence the "setTimeout()"
     */

    setTimeout(function () {
        PreviousUrlAddress = $.address.value();

        /**
         * Class’ifying UNOs – (brilliant suggestion re giving groups CSS classes, as I’m assuming this will
         * give us the ability to make classes visible, invisible, %opaque, etc. - no matter where those
         * groups are within the SVG) Any group can be assigned to one or more CSS classes. Those classes can
         * be accessed - eg made visible/invisible - no matter where they sit in the SVG, or if their parent
         * groups are on or off. Assigning classes with Illustrator can be done by adding a class name to the
         * group name with the syntax: “objectName class:className1 class:className2”
         *
         * For example, the UNO named in Illustrator as: Individuals class:stakeholder class:people which will
         * generate the following SVG: <g id="Individuals_class:stakeholder_class:people">
         *
         * The code will parse that into an UNO with an id named “Individuals” (for URL command and DB
         * purposes), with the CSS classes of “stakeholder” and “people”. Un-Named groups can also be assigned
         * classes eg a group with: <g id="_class:stakeholder_class:people"> Any bit of SVG artwork - anywhere
         * in the SVG - could potential be assigned a class and controlled via CSS class functions. Classes
         * can be controlled using the special rrr-group to trigger a JSON formatted command (I’m assuming
         * that’s how we can do things like turn on/off all class=”connections”)
         */

        // this was a test thing: idiagramUtil.findNotCssNotViewport();
        if (!$(svgness).hasClass("preprocessed")) {
            $(svgness).find("g[id]:not([id^='css']):not([id^='viewport'])").each(function () {
                var thisId = $(this).attr("id");
                // first, see if there are classes at all in
                // the id. If not, don' worry 'bout it.
                var pattern;
                var result;
                //var id = "";
                pattern = /_class:/;
                result = pattern.exec(thisId);
                if (result) {
                    /**
                     * get the id if there is one. the regExp pattern is saying, get the id
                     * at beginning of line (represented by "^(.+)" , which means1 or more
                     * characters at beginning of the line), but not preceeded by the
                     * characters, "_class:"
                     */
                    pattern = /(?!_class:)^(.+?)(?:_class:)/;
                    result = pattern.exec(thisId);
                    if (result) {
                        // set the id for this group without the class stuff in it
                        $(this).attr("id", result[1]);
                    } else {
                        // there is no id, only classes
                        $(this).removeAttr("id");
                    }
                    // now get a list of the classes
                    pattern = /(_class:)([a-zA-Z0-9\.\-]*)/g;
                    var listOfClasses = thisId.match(pattern);
                    //var classString = "";
                    for (var i = 0; i < listOfClasses.length; i++) {
                        // filter out the word, "_class:"
                        addClass(this, listOfClasses[i].substr(7));
                    }
                    // copy the real id to thisId
                    if (result) {
                        thisId = result[1];
                    } else {
                        // there is no id and nothing to scrub
                        return;
                    }
                }
                // scrub bad id's with _ character

                var scrubbedId = stripId(thisId);
                if (!/^sss|^xxx|^vvv|^ooo/.test(scrubbedId)) {
                    if (thisId !== scrubbedId) {
                        // save the id without stuff like _2_
                        $(this).attr("id", scrubbedId);
                    }
                }
            });

            /**
             * You just fixed up some id's. Now go and see if there are any missing id's that show up in the database but not the SVG.
             * If there are, create dummy id elements in the svg. This is a bad hack, I know; not my idea.
             **/
            $(svgness).find("g[id]:not([id^='css']):not([id^='viewport'])").each(function () {
                var thisId = $(this).attr("id");


                // scrub bad id's with _ character
                var scrubbedId = stripId(thisId);
                if (!/^sss|^xxx|^vvv|^ooo/.test(scrubbedId)) {

                    var classesFromDBForThisId = getDatabase(scrubbedId, "classes");
                    if (classesFromDBForThisId != undefined && classesFromDBForThisId.length) {
                        $("#" + scrubbedId).addClass(classesFromDBForThisId);
                    }
                }
            });

            /**
             * Go through all sss groups find all its descendant groups with an id because we don't do anything
             * with them Also add the ss class name to the sss groups
             */
            $(svgness).find("g[id^='sss']").each(function (index) {
                addClass(this, "sss");
                $(this).attr("style", "pointer-events: none");
                // This id is messing up the css rules
                $(this).removeAttr("id");
                // get rid of all groups with an id under groups where the id starts with sss
                $(this).find("g[id]").each(function () {
                    $(this).removeAttr("id");
                });
            });

            /**
             * find all id's that do not have a special prefix and set a class to ddd.
             * Give them defaul class of off look for immediate vvv,ooo,ccc, etc prefixes
             * and append the  parent id to them CSS should make "off" class invisible and
             * have no mouse events.
             */
            $(svgness)
                .find(
                    "g[id]:not([id^='sss']):not([id^='ooo']):not([id^='xxx']):not([id^='vvv']):not([id^='ccc']):not([id^='css']):not([id^='viewport']):not([id^='svg-pan-']):not(symbol g)")
                .each(function (index) {
                    var unoId = $(this).attr("id");
                    // unoId = stripId(unoId); //we did this
                    // above
                    var topClass = this;

                    // If this has a child xxx or ooo or vvv group, See if we are missing other required groups, such as xxx or ooo or vvv
                    var isDdd = $(this).children("g[id^='ooo'], g[id^='vvv'],g[id^='xxx']").length;
                    if (isDdd) {
                        var divToWrap;
                        //Yes we have a ddd. Now generate any missing groups.
                        if (!$(this).children("g[id^='ooo']").length) {
                            //generate an ooo
                            divToWrap = document.createElement("g");
                            $(divToWrap).attr({
                                id: "ooo"
                            });
                            $(this).append(divToWrap);
                        }
                        if (!$(this).children("g[id^='vvv']").length) {
                            //generate an vvv
                            divToWrap = document.createElement("g");
                            $(divToWrap).attr({
                                id: "vvv"
                            });
                            $(this).append(divToWrap);
                        }
                        if (!$(this).children("g[id^='xxx']").length) {
                            //generate an xxx
                            divToWrap = document.createElement("g");
                            $(divToWrap).attr({
                                id: "xxx"
                            });
                            $(this).append(divToWrap);
                        }
                    }

                    $(this).children("g[id^='ooo'], g[id^='vvv'],g[id^='xxx']").each(function (index) {
                        // Only add ddd and off if this is a UNO class with interactive children
                        addClass(topClass, "ddd");
                        addClass(topClass, "uno");

                        $(topClass).attr("mytype", "ddd");
                        // even the ddd (or uno) types get the uno-id attribute popoulated
                        $(topClass).attr("uno-id", unoId);
                        var thisId = $(this).attr("id");
                        thisId = stripId(thisId);
                        /**
                         * TODO: Make sure you strip the -1 -2 stuff from the prefixed id's. before you do the mytype
                         * and class = "ccc" or whatever. We don't want ccc-2. We just want
                         * ccc thisId = stripId(thisId); maybe it will be useful if we add a class named the same as
                         * the prefix addClass(this, thisId);  addClass(this, "off"); creates an attribute called
                         * mytype and sets it to ooo or vvv or whatever it is.
                         */
                        addClass(this, thisId);

                        $(this).attr("mytype", thisId);
                        // puts the UNO id into the attribute uno-id
                        $(this).attr("uno-id", unoId);
                        thisId += unoId;
                        $(this).attr("id", thisId);
                    });
                    /**
                     * Now while We have this group id, see if it was assigned a type If it doesn't, it is just a
                     * wrapper and we need to know that.
                     */
                    var isWrapper = $(this).attr("mytype");
                    if (isWrapper === undefined || !isWrapper.length) {
                        //
                        var thisId = $(this).attr("id");
                        $(this).attr("uno-id", thisId);
                        var isThisInDb = getDatabase(thisId, "id");
                        if (!isThisInDb.length) {
                            // wrapper type whose id is not is not in database.
                            $(this).attr("mytype", "wrapNotDb");
                            addClass(this, "wrapNotDb");
                            addClass(this, "uno");
                        } else {
                            // this is in database. May have tooltip to show off.
                            $(this).attr("mytype", "wrapInDb");
                            addClass(this, "wrapInDb");
                            addClass(this, "uno");
                        }
                    }
                });

            //Now go through and set a class for artwork
            $(".svg-pan-zoom_viewport")
                .find(
                    "*:not(g)")
                .each(function () {
                    $(this).addClass("lm-art");

                });
            $(svgness).addClass("preprocessed");
        }
        //***********  The stuff above should be put through a preprocessor, then save off the svg file.
        // Now go through and set mouse events
        $(svgness)
            .find(
                "g[id]:not([id^='placeholder__']):not([id^='sss']):not([id^='css']):not([id^='viewport']):not([id^='svg-pan-']):not(symbol g)")
            .each(function (index) {
                // we need to assign "this" to one of these objects:
                // ddd (which has a child with a prefix),
                // vvv,
                // ooo,
                // ccc,
                // wrapInDb wrapper with id in db, which DOES NOT
                // have a child with a prefix
                // wrapNotDb wrapper without id in db, which DOES
                // NOT have a child with a prefix
                var gIdObject = null;
                switch ($(this).attr("mytype")) {
                    case "ddd":
                        gIdObject = new baseIdClass.DddClass(this);
                        break;
                    case "vvv":
                        gIdObject = new baseIdClass.VvvClass(this);
                        break;
                    case "ooo":
                        gIdObject = new baseIdClass.OooClass(this);
                        break;
                    case "xxx":
                        gIdObject = new baseIdClass.XxxClass(this);
                        break;
                    case "wrapInDb":
                        gIdObject = new baseIdClass.WrapInDbClass(this);
                        break;
                    case "wrapNotDb":
                        if ($(this).attr("uno-id").length > 0) {
                            // we were getting bugs after id's filled with junk were getting stripped to 0-length values
                            gIdObject = new baseIdClass.WrapNotDbClass(this);
                        } else {
                            // if id.length = 0 then remove the uno stuff from this. Make a normal id'less <g>
                            $(this).removeAttr("mytype");
                            $(this).removeAttr("id");
                            $(this).removeAttr("uno-id");
                            removeClass(this, "wrapNotDb");
                            removeClass(this, "off");
                        }
                        break;
                    default:
                        break;
                }
                // Add this to the object that contains a list of these.
                if (gIdObject) {
                    idGroupObject[gIdObject.thisId] = gIdObject;
                }
            });
        /**
         * see initialize() to see what we are doing here. Now all these  have been created, need to init some of its
         * variables, such as cccGIdObject
         */
        callAllGroups("initialize");

        $.address.change(function (e) {
            // TODO: Replace the parameter list generation with library function or URLSearchParams API
            var locationString = $.address.queryString();
            var parameterTokens = locationString.split("&");
            var parameterList = [];
            for (var j = 0; j < parameterTokens.length; j++) {
                var parameterName = parameterTokens[j].replace(/(.*)=.*/, "$1");
                var parameterValue = parameterTokens[j].replace(/.*=(.*)/, "$1");
                parameterList[parameterName] = parameterValue;
            }
            var q = parameterList;
            var filteredKeyVals = getAllKeysAndValuesStartingWith(q, "gotoz");
            var thisId = null;
            // grab last id on the list to highlight it.
            var objectId = filteredKeyVals["gotoz"];
            if (objectId && objectId.length) {
                thisId = objectId;
            } else {
                filteredKeyVals = getAllKeysAndValuesStartingWith(q, "unoInfo");
                        objectId = filteredKeyVals["unoInfo"];

                        if (objectId && objectId.length) {
                            thisId = objectId;
                        }
                        else {
                filteredKeyVals = getAllKeysAndValuesStartingWith(q, "open");
                objectId = filteredKeyVals["open"];
                if (objectId && objectId.length) {
                    thisId = objectId;
                } else {
                    objectId = filteredKeyVals["openall"];
                    if (objectId && objectId.length) {
                        thisId = objectId;
                    } else {
                        filteredKeyVals = getAllKeysAndValuesStartingWith(q, "on");
                        // grab last id on the list to highlight it.
                        objectId = filteredKeyVals["on"];
                        if (objectId && objectId.length) {
                            thisId = objectId;
                        }
                    }
                }
            }
        }

        var arrThisId = [] ;
        if(arrThisId.includes(',')){
            arrThisId =  thisId.split(','); //getting the last unoinfo

            if(arrThisId.length > 1){
                thisId = arrThisId [ arrThisId.length - 1 ];
            }
        }

        if(thisId != unoInfoAnterior) {
           // alert(thisId)
        unoInfoAnterior = thisId;
        }

/*
            var unoGIdObject = idGroupObject[thisId];

           /* if (unoGIdObject !== undefined) {
               // populateInfoPane(unoGIdObject);
            } else if (idiagramSvg.database.get(objectId) != undefined) {
               // populateInfoPaneWithMapDbEntry(objectId);
            } else {
                console.error("Cannot fetch info for unknown unoId: " + objectId);
            }

            if (thisId !== undefined && thisId !== null && thisId !== 0 && idGroupObject[thisId] !== undefined) {
              //  populateInfoPane(idGroupObject[thisId]);
            }
            */
        });

        $.address.externalChange(function (e) {


                flagDelay = false;
                idiagramSvg.flagDelay = false; //inform to idiagram object that the delayed commands should be stopped



            var val = $.address.value();
            val.replace(/\+\+\+\=/g, '+++'); //get rid of = sign that creeps in sometimes.
            val.replace(/\-\-\-\=/g, '---'); //get rid of = sign that creeps in sometimes.
            $.address.value(val);

            // Reset all videos - both overmapVideo and undermapVideo
            $('#overmapVideo, #undermapVideo').each(function () {
                this.pause();
                this.currentTime = 0;
                this.style.visibility = 'hidden';
            });
            $('.svgMap').each( function() {
                this.style.pointerEvents = "auto";
            });
           // $('.svgMap').css('pointer-events', 'auto');


            setTimeout(function () {
                // See if there are any address parameters. If not, populate some with the current state.
                var parameterList = getURLParameterList($.address.queryString());
                if (parameterList && parameterList.length) {
                    // run this stuff if we have a +++ command. It makes no changes except what commands come after +++
                    if (isKeyInParameterList(parameterList, ["+++"])) {
                        parameterList = purgeMatchedStatesFromParamList(parameterList);
                        $.address.history(false);
                        $.address.autoUpdate(false);
                        // need to get this.parameterList less the "+++" stuff
                        var purgedParameterList = removeCommandFromParameterListArray(parameterList, "+++");

                        // TODO: Need to purge duplicate commands from parameter list
                        var myUrlAddress = parameterListArrayToUrlAddress(purgedParameterList);

                        /**
                         *  I just found a case where designer is sending in +++&panzoom, which duplicates the existing
                         *  panx and pany, plus zoom. So I need to delete previous panx, pany, zoom commands
                         *  from the PreviousUrlAddress so we can keep and use the new panx, panx values
                         *
                         *  TODO: These panx, pany are actually not getting removed from url.   Need to fix that.
                         */
                        if (isKeyInParameterList(purgedParameterList, ["panzoom"])) {
                            PreviousUrlAddress = removeCommandFromAddressDotValueString(PreviousUrlAddress, "panx");
                            PreviousUrlAddress = removeCommandFromAddressDotValueString(PreviousUrlAddress, "pany");
                            PreviousUrlAddress = removeCommandFromAddressDotValueString(PreviousUrlAddress, "zoom");
                        }

                        var newUrl = PreviousUrlAddress + "&" + myUrlAddress;
                        // there needs to be a question mark ("?") as second character. If it is not there, insert it
                        newUrl = verifyQuestionMarkInURL(newUrl);
                        $.address.value(newUrl);
                        if (!isKeyInParameterList(parameterList, ["panx", "panzoom"])) {
                            updateAddress();
                        } else {
                            $.address.history(true);
                            $.address.update();
                            $.address.autoUpdate(true);
                        }
                        /**
                         * TODO: Maybe later code processing the location path that is right after the hash I put this
                         * here instead of up higher because we needed to purge the hidden uno's from parameter list.
                         */
                        processCommandsInURL(parameterList, null);
                    } else {
                        if (!isKeyInParameterList(parameterList, ["panx", "panzoom"])) {
                          //  updateAddress(); //remove to fix #2781 gotoz bug
                        }
                        processAddress();
                    }

                    PreviousUrlAddress = $.address.value();
                }
            }, 500);
        });

        var parameterList = getURLParameterList($.address.queryString());
        // if we don't have panx we probably don't have any pan/zoom parameters in url
        if (!isKeyInParameterList(parameterList, ["panx", "panzoom"])) {
            updateAddress();
        }
        flagDoNoResetMap = false;

        processAddress();

        /**
         * Handle hovering over tagged words in text We need to do this everytime we populate stuff with text,
         * so user can hover over stuff And mimic hovering over objects in svg
         */
        // TODO: Make objects for these so we don't keep creating new pane events and processing pane markup.
        createHoverEventForTaggedWords();

        $(window).resize(function () {
            //This will completely restart the svg-pan-zoom library
            reloadZoomTiger(); //reload zoomTiger from svg-pan-zoom.js
            var doResizeFitPan = function () {
                zoomRatio = zoomTiger.getZoom();
                updateAddress();
            };

            setTimeout(function () {
                zoomTiger.resize();
                zoomTiger.fit();
                zoomTiger.center();
                doResizeFitPan(); //TODO: Take this out of this function and put it back here
            }, 400);
        });

        $(svgness).click(function (e) {
            if (!$(':focus').is("body")) {
                $(':focus').blur(); //Sets focus to body so we can do things like g-click.
            }

            if (e.shiftKey) {
                updateAddress(null, true);
            } else if ($(e.target).closest("#svg-pan-zoom-reset-pan-zoom").length) {

                if (idiagramSvg.options && idiagramSvg.options.onResetClick) {
                    idiagramSvg.options.onResetClick();
                } else onResetClick();
            }
        });

        /**
         * Everything is finished processing. Now ready to display the svg.
         * Note: I am loading in the css last so that css is not trying to draw elements before they are finished processing
         * TODO: CSS files should be concatenated during the build process
         */
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/mapWindow.min.css" + "' type='text/css' />");
        $("head").append('<link rel="stylesheet" href="map.css" />');
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/jquery.qtip.min.css" + "' type='text/css' />");
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/svg-styles.min.css" + "' type='text/css' />");
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/fontello/css/fontello.min.css" + "' type='text/css' />");
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/menustyles.min.css" + "' type='text/css' />");
        $("head").append("<link rel='stylesheet' href='" + "/stylesheets/bootstrap-iso.min.css" + "' type='text/css' />");
        idiagramSvg.doTweeningInSvgPanZoom = true; //Set this true after first initializing so svg-pan-zoom & other initialization will start tweening.
        $("#container").css("visibility", "visible");
        $(window).triggerHandler("svgloadedComplete");
    }, 1);
} // end of svgloaded


var beforePan;
beforePan = function (oldPan, newPan) {
    var gutterWidth = 100, //getGlobal("gutterWidth"),
        gutterHeight = 100, //getGlobal("gutterHeight"),
        // Computed variables
        sizes = this.getSizes(),
        leftLimit = -((sizes.viewBox.x + sizes.viewBox.width) * sizes.realZoom) + gutterWidth,
        rightLimit = sizes.width - gutterWidth - (sizes.viewBox.x * sizes.realZoom),
        topLimit = -((sizes.viewBox.y + sizes.viewBox.height) * sizes.realZoom) + gutterHeight,
        bottomLimit = sizes.height - gutterHeight - (sizes.viewBox.y * sizes.realZoom);
    var customPan = {};
    customPan.x = Math.max(leftLimit, Math.min(rightLimit, newPan.x));
    customPan.y = Math.max(topLimit, Math.min(bottomLimit, newPan.y));
    return customPan;
};


/**
 * getDatabaseAndInfoFile() is only called once when page is first loaded.  If we do not have an updated map db, then it is loaded
 * from server, else use the local persistent copy.
 **/
function getDatabaseAndInfoFile(localVersion) {
    // svg file found from global in html file or json file with same first name as html file.
    lastEmbedSrc = designerPrefs !== undefined && designerPrefs.svgFile !== undefined ? designerPrefs.svgFile : "";
    /**
     * Load the database for this svg.
     */
    var dataLength = 0;
    var doc2data = new Map();
    masterDatabase = doc2data;
    idiagramSvg.database = database = masterDatabase;

    //This is called when we are finished grabbing map data from local database.
    $(idiagramSvg.masterForage).on("masterForageIterateCompleted", function () {
        $(idiagramSvg.masterForage).off("masterForageIterateCompleted"); //don't need this event trigger taking up memory anymore.
        getOverRideDatabase(masterDatabase);
    });
    $(idiagramSvg.lastUpdatedDb).on("lastUpdatedDb_setItem_complete", function () {
        $(idiagramSvg.lastUpdatedDb).off("lastUpdatedDb_setItem_complete"); //don't need this event trigger taking up memory anymore.
        getOverRideDatabase(masterDatabase);
    });
    if (designerPrefs.masterDB !== undefined && designerPrefs.masterDB.length) {

        $.get("/mapdata/" + designerPrefs.masterDB + "/" + localVersion.toString(), function (res, status) {
            // TODO: Create better error-handling
            if (status === "success" && res.data == true) {
                dataLength = res.doc.length;
                var data = res.doc; //this contains the rows from the map db
                for (var i = 0; i < dataLength; i++) {
                    //TODO: we should clear out all the data from this local database collection first.
                    doc2data.set(data[i].doc.id, data[i].doc);
                    masterForage.setItem(data[i].doc.id, data[i].doc, function (err, value) {
                        // Do other things once the value has been saved.
                        if (err) {
                            return null;
                        }
                    });
                }
                idiagramSvg.lastUpdatedDb.setItem(idiagramSvg.designerPrefs.masterDB, res.serverVersion).then(function (value) {
                    // Do other things once the value has been saved.
                    getOverRideDatabase(masterDatabase);
                    return null;
                }).catch(function (err) {
                    console.error(err);
                });
            } else if (localVersion) {
                // do this if our local database is already updated
                idiagramSvg.masterForage.iterate(function (value, key, iterationNumber) {
                    // Resulting key/value pair -- this callback will be executed for every item in the database.
                    doc2data.set(key, value);
                }).then(function () {
                    $(idiagramSvg.masterForage).triggerHandler("masterForageIterateCompleted");
                    return null;
                }).catch(function (err) {
                    console.log(err);
                });
            } else {
                return getOverRideDatabase(null);
            }
        });
    }


    if (typeof designerPrefs.segmentsFile !== "undefined" && designerPrefs.segmentsFile !== undefined && designerPrefs.segmentsFile.length) {
        $.get(designerPrefs.segmentsFile, function (data, status) {
            if (status === "success") {
                present.PresentationJson = data;
                // process json file
                return present.processAnimatePresentate(); //note that right now we cannot handle loading multiple segmentsFile's
            } else {
                return console.error("Error loading SegmentsFile: " + status);
            }
        });
    } else if (designerPrefs.hasOwnProperty("SegmentsFile")) {
        console.log("SegmentsFile is now deprecated. Use lower case 's', as in 'segmentsFile'");
    }
}

//reload zoomTiger from svg-pan-zoom.js
function reloadZoomTiger() {
    zoomTiger = null; //hopefully enables junk collections
    // set up pan and zoom object. This adds some navigation groups and a group with .svg-pan-zoom_viewport

    var eventsHandler;
    eventsHandler = {
        haltEventListeners: ['touchstart', 'touchend', 'touchmove', 'touchleave', 'touchcancel'],
        init: function (options) {
            var instance = options.instance,
                initialScale = 1,
                pannedX = 0,
                pannedY = 0;
            // Init Hammer
            // Listen only for pointer and touch events
            this.hammer = Hammer(options.svgElement, {
                inputClass: Hammer.SUPPORT_POINTER_EVENTS ? Hammer.PointerEventInput : Hammer.TouchInput
            });
            // Enable pinch
            this.hammer.get('pinch').set({
                enable: true
            });
            // Handle press
            this.hammer.on('press', function (ev) {
                runToggleMenuOn(ev);
            });
            // Handle pan
            this.hammer.on('panstart panmove', function (ev) {
                // On pan start reset panned variables
                if (ev.type === 'panstart') {
                    pannedX = 0;
                    pannedY = 0
                }
                // Pan only the difference
                instance.panBy({
                    x: ev.deltaX - pannedX,
                    y: ev.deltaY - pannedY
                });
                pannedX = ev.deltaX;
                pannedY = ev.deltaY
            });
            // Handle pinch
            this.hammer.on('pinchstart pinchmove', function (ev) {
                // On pinch start remember initial zoom
                if (ev.type === 'pinchstart') {
                    initialScale = instance.getZoom();
                    instance.zoom(initialScale * ev.scale)
                }
                instance.zoom(initialScale * ev.scale)
            });
            // Prevent moving the page on some devices when panning over SVG
            options.svgElement.addEventListener('touchmove', function (e) {
                e.preventDefault();
            });
        },
        destroy: function () {
            this.hammer.destroy()
        }
    };

    zoomTiger = svgPanZoom(svgness, {
        // beforePan: beforePan,
        zoomEnabled: true,
        maxZoom: designerPrefs.hasOwnProperty("maxZoom") ? designerPrefs.maxZoom : 10, // Maximum Zoom level
        minZoom: designerPrefs.hasOwnProperty("minZoom") ? designerPrefs.minZoom : 0.5, // Minimum Zoom level - added by Marshall Clemens
        zoomScaleSensitivity: designerPrefs.zoomSensitivity, // Zoom sensitivity
        onPan: resetDuration,
        onZoom: resetDuration,
        // we are using double-click to jump to url defined in db for this object
        dblClickZoomEnabled: false,
        controlIconsEnabled: false,
        customEventsHandler: eventsHandler,

        fit: 1,
        center: 1,
        panEnabled: true,
        preventMouseEventsDefault: false
        // TODO: make sure this is set (right)to false?) so that clicks can do more than what svg-pan-zoom handles
    });
    idiagramSvg.zoomTiger = zoomTiger;
}

function onResetClick() {
    zoomTiger.resize();
    zoomTiger.fit();
    zoomTiger.center();
    zoomRatio = zoomTiger.getZoom();
    updateAddress();
}

function THIS_IS_WHERE_IT_ALL_STARTS() {
}

$(function () {
    /**
     * Important: As of jQuery 1.4, if the JSON file contains a syntax error, the request will usually
     * fail silently. Avoid frequent hand-editing of JSON data for this reason. JSON is a data-interchange
     * format with syntax rules that are stricter than those of JavaScript's object literal notation.
     * For example, all strings represented in JSON, whether they are properties or values, must be
     * enclosed in double-quotes. For details on the JSON format, see http://json.org/.
     *
     * first figure name of the designerprefs.json file. If it is there, it is the same name as the html file.
     */
    $.getJSON("config.json", function (j) {
        idiagramSvg.designerPrefs = designerPrefs = j; //one global replaces a lot of little ones.
        //create local master database
        if (designerPrefs.masterDB !== undefined && designerPrefs.masterDB.length) {
            masterForage = idiagramSvg.masterForage = localforage.createInstance({
                name: designerPrefs.masterDB
            });
        }
        if (designerPrefs.overrideDB !== undefined && designerPrefs.overrideDB.length) {
            overrideForage = localforage.createInstance({
                name: designerPrefs.overrideDB
            });
        }

        //create local database of dates so I know if remote database (and other stuff?) has been updated since I downloaded it.
        idiagramSvg.lastUpdatedDb = lastUpdatedDb = localforage.createInstance({
            name: "lastUpdatedDb"
        });

        $(idiagramSvg.lastUpdatedDb).on("setitem_complete", function () {
            $(idiagramSvg.lastUpdatedDb).off("setitem_complete"); //don't need this event trigger taking up memory anymore.
            getDatabaseAndInfoFile(0); //0 because this tells the server that we have nothing yet.
        });

        //This is a funcky callback since I can't get localForage callbacks to work
        $(idiagramSvg.lastUpdatedDb).on("getitem_complete", function (event, version) {
            $(idiagramSvg.lastUpdatedDb).off("getitem_complete"); //don't need this event trigger taking up memory anymore.
            if (version !== undefined && version !== null) {
                getDatabaseAndInfoFile(version);
            } else {
                //this is not populated yet in the local version db, so populate this master version now, then call getDatabaseAndInfoFile(0);
                idiagramSvg.lastUpdatedDb.setItem(idiagramSvg.designerPrefs.masterDB, 0).then(function (value) {
                    // Do other things once the value has been saved.
                    $(idiagramSvg.lastUpdatedDb).triggerHandler("setitem_complete");
                    return null;
                }).catch(function (err) {
                    console.error(err);
                });
            }
        });

        //get version of master
        return idiagramSvg.lastUpdatedDb.getItem(idiagramSvg.designerPrefs.masterDB).then(function (value) {
            $(idiagramSvg.lastUpdatedDb).triggerHandler("getitem_complete", [value]);
            return null;
        }).catch(function (err) {
            console.error(err);
        });
    })
        .done(function () {
            return null;
        })
        .fail(function () {
            // TODO: Test to see if all of these parameters are defined
            // if (typeof svgFile !== "undefined") {
            //   idiagramSvg.designerPrefs = designerPrefs = {
            //     "svgFile": svgFile,
            //     "folder": folder,
            //     "masterDB": masterDB,
            //     "overrideDB": overrideDB,
            //     "zoomSensitivity": designerPrefs.zoomSensitivity = typeof designerPrefs.zoomSensitivity !== "undefined" ? designerPrefs.zoomSensitivity : 5.5,
            //     "defaultURL": defaultURL
            //   };
            // }
            // else
            alert("Error reading config.json." /* , and no svgFile defined in html file" */);
            console.warn("Error getting config.json. The map cannot be loaded.");
            return getDatabaseAndInfoFile(0);
        })
}); //end if $(function  ..called after page loads

function eventHandler(eventValue) {
    this.type = "eventHandler";
    this.eventValue = eventValue;
}

eventHandler.prototype.triggerStuff = function () {
    $(this).triggerHandler(this.eventValue);
};

function runASlideInSlideShow($thisSlide) {
    // TODO: Replace URL parameter processing
    var locationString = $thisSlide.prop("hash");
    locationString = locationString.replace(/.*\#(.*)/, "$1");
    var parameterString = locationString.replace(/.*\?(.*)/, "$1"); //I think I am replacing the text after the '?' with the text after the hash
    var parameterList = getURLParameterList(parameterString);
    processCommandsInURL(parameterList, null);
    // we don't really use anchorValues here
    var anchorValues = {};
    var thisId = getLastIdParameterOnClickedAnchor($thisSlide, anchorValues);
    if (thisId !== undefined && thisId !== 0 && idGroupObject[thisId] !== undefined) {
        populateInfoPane(idGroupObject[thisId]);
    }
}

/**
 * Called when user hits the h key or from the drop-down menu.
 * It shows a modal help window. The html must be set up to show this. See documentation
 */
function showHelp() {
    if ($('#myModal').length) {
        if (designerPrefs.hasOwnProperty("helpLink")) {
            //This will add dynamic content to the story pane.
            //if we already have content for help, do not try to load it again.
            if ($("#modal-body").children().length === 0) {

                $.get(designerPrefs.helpLink, function (fileContents, status) {
                    if (status === "success") {
                        var dyn = $("#modal-body")[0];
                        if (dyn) {
                            $(dyn).html(fileContents);
                            $('#myModal').modal('show');
                        }
                    } else {
                        console.log("Error getting help file: " + status);
                    }
                }.bind(this));
            } else {
                $('#myModal').modal('show');
            }
        }
    } else {
        var htmlFileName = location.pathname.substring(location.pathname.lastIndexOf("/") + 1);
        console.log("Error. Cannot display the help information because the help file elements are missing from the " + htmlFileName + " file!")
    }
}

function zoomAll(e) {
    e.stopImmediatePropagation();

    //It takes a bit to do the resize/fit/center stuff.  So test to see if pan is finished
    var doResizeFitPan = function () {
        // Capture the zoom value - that fits the SVG to the current window size. Use to calculate relative zoom values.
        zoomRatio = zoomTiger.getZoom();
        updateAddress();
    };
    zoomTiger.resize();
    zoomTiger.fit();
    zoomTiger.center();
    doResizeFitPan(); //TODO: Take this out of this function and put it back here
}

/*
 * This will navigate through the anchor tags that contain slide class name When
 * pane content is shown, it needs to set the vars, Slides and SlideSelected.
 * TODO: We need sets of these vars for the different panes
 */
$(window).keydown(function(e) {
    if (!zoomTiger) return;
    var $thisSlide;
    /**
     * FIXME: The jQuery Address plugin appends = to the +++ or --- commands.
     * This should not cause a problem but appears to do so under some circumstances. The hack solution below is to
     * simply remove this = sign upon any keystroke and before $.address.update() is called.
     */

    var addrVal = $.address.value();
    $.address.value(addrVal.replace(/(\+\+\+|\-\-\-)=/, '$1')); //don't do anything if focus is on a textarea or input

    var nodeName = e.target.nodeName.toLowerCase();

    if (nodeName == "textarea" || nodeName == "input" && e.target.type.toLowerCase() == "text") {
        return;
    }

    Slides = $("a.slide");
    var selectedMenuItem = window.sharedData.selectedMenuItem;
    Slides.each(function(index) {
        if ($(this).attr('id') === "storyLink" + selectedMenuItem) {
            SlideSelected = index;
        }
    });

    if (e.shiftKey && e.keyCode == 188 || e.keyCode == 36) {
        //clicked on shift-comma or home
        SlideSelected = 0; //jump to beginning

        $thisSlide = $(Slides.get(SlideSelected));
        window.sharedData.selectedMenuItem = $thisSlide.attr('id').replace('storyLink', '');
        $thisSlide.focus();
        runASlideInSlideShow($thisSlide);
    } else if (e.shiftKey && e.keyCode == 190 || e.keyCode == 35) {
        //clicked on shift-period or end key
        SlideSelected = Slides.length - 1; //jump to end

        $thisSlide = $(Slides.get(SlideSelected));
        window.sharedData.selectedMenuItem = $thisSlide.attr('id').replace('storyLink', '');
        $thisSlide.focus();
        runASlideInSlideShow($thisSlide);
    } //None of the commands below should have multiple keys, such as control-h or whatever.
    //Also make sure we don't have the focus on a form.


    if (!e.altKey && !e.ctrlKey && !e.metaKey && !e.shiftKey) {
        if (e.which === 190 || e.which === 34) {
            //typed in period or page down
            if (Slides.length) {
                //if we have any slides at all to navigate
                //clicked on period or page-down
                SlideSelected++;

                if (SlideSelected >= Slides.length) {
                    SlideSelected = Slides.length - 1;
                }

                if (SlideSelected < 0) {
                    SlideSelected = 0;
                }

                $thisSlide = $(Slides.get(SlideSelected));
                window.sharedData.selectedMenuItem = $thisSlide.attr('id').replace('storyLink', '');
                $thisSlide.focus();
                runASlideInSlideShow($thisSlide);
            }
        } else if (e.which === 188 || e.which === 33) {
            //typed in comma or page up
            if (Slides.length) {
                //if we have any slides at all to navigate
                //clicked comma or page-up
                SlideSelected--;

                if (SlideSelected >= Slides.length) {
                    SlideSelected = Slides.length - 1;
                }

                if (SlideSelected < 0) {
                    SlideSelected = 0;
                }

                $thisSlide = $(Slides.get(SlideSelected));
                window.sharedData.selectedMenuItem = $thisSlide.attr('id').replace('storyLink', '');
                $thisSlide.focus();
                runASlideInSlideShow($thisSlide);
            }
        } else if (e.which == 86) {
            // if key is "v"
            e.stopImmediatePropagation();
            var v = $("#version");

            if (!v || !v.length) {
                $(".narration-pane").prepend("<div id='version'>" + version + "</div>");
            } else {
                $(v).toggle();
            }
        } else if (e.which === 65) {
            //clicked a key
            //c-click will will open a closed UNO, and close an open one
            idiagramSvg.gClicked = "a"; //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.gClicked = null;
            }, 600);
        } else if (e.which === 67) {
            //clicked c key
            //c-click will will open a closed UNO, and close an open one
            idiagramSvg.gClicked = "c"; //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.gClicked = null;
            }, 600);
        } else if (e.which === 71) {
            //clicked g key
            //Keyboard shortcut: A g-click on an UNO will trigger a gotoz=thatUNO
            idiagramSvg.gClicked = "g"; //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.gClicked = null;
            }, 600);
        } else if (e.which === 77) {
            //m-click on a uno will open the mapform for that uno
            //Keyboard shortcut: A m-click on an UNO will trigger a edit=thatUNO
            idiagramSvg.gClicked = "m"; //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.gClicked = null;
            }, 600);
        } else if (e.which === 76) {
            //clicked l (el) key
            //On an UNO: toggle highlight for that UNO
            idiagramSvg.elClicked = "l"; //lower-case "L" (el)
            //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.elClicked = null;
            }, 600);
        } else if (e.which === 85) {
            //clicked u key, which Unhighlights all UNOs -  unhlt=all
            var urlCommand = "+++&unhlt=all";
            $.address.history(true);
            addCommandToURL("unhlt", "all");
            $.address.history(false);

            if (urlCommand !== null) {
                // get a list of parameters and execute them
                urlCommand = urlCommand.replace(/^\?/, ""); //replace leading "?" with nothing

                var q = getURLParameterList(urlCommand);
                processCommandsInURL(q);
            }
        } else if (e.which === 79) {
            //clicked o key
            //c-click will will open a closed UNO, and close an open one
            idiagramSvg.gClicked = "o"; //now set it back to null after a short time

            setTimeout(function() {
                idiagramSvg.gClicked = null;
            }, 600);
        } else if (e.which === 72) {
            //clicked h key to get help as a modal popup
            showHelp();
        } else if (e.which === 69) {
            //clicked e key. want to toggle the east pane
            idiagramUi.myLayout.toggle("east");
            updateAddress("East Pane Toggled", true);
        } else if (e.which === 87) {
            //clicked w key. want to toggle the east pane
            idiagramUi.myLayout.toggle("west");
            updateAddress("West Pane Toggled", true);
        } else if (e.which == 37 || e.which == 38 || e.which == 39 || e.which == 40) {
            // Arrow key for panning
            var pan = zoomTiger.getPan();
            var panStep = 100; // the pan increment to be used for all

            if (e.which == 37) {
                // Left-arrow key
                pan.x = pan.x + panStep;
                zoomTiger.pan(pan);
            } else if (e.which == 39) {
                // Right-arrow key
                pan.x = pan.x - panStep;
                zoomTiger.pan(pan);
            } else if (e.which == 38) {
                // Up-arrow key
                pan.y = pan.y + panStep;
                zoomTiger.pan(pan);
            } else if (e.which == 40) {
                // Down-arrow key
                pan.y = pan.y - panStep;
                zoomTiger.pan(pan);
            }
        } else if (e.keyCode == 27) {
            //escape key pressed, so show svg instead of map db editor
            $(".information-pane").show();
            $("#mapDbEditor").hide();
        }
    }
});

function normalizeMapPane() {
    // Setup svg-pan-zoom for the new window size - without altering the user's view of the map.

    // Capture the current values
    var oldpan = zoomTiger.getPan();
    var oldzoom = zoomTiger.getZoom();

    var getZoomRatio = function () {
        zoomRatio = zoomTiger.getZoom();
        // Reset the old pan and zoom values - so we don't change the user's current view
        zoomTiger.zoom(oldzoom);
        zoomTiger.pan(oldpan);
    };
    // Set up for the new window size
    zoomTiger.resize();
    zoomTiger.fit();
    getZoomRatio(); //TODO: Pull stuff out of this function and put back here
}

/**
 * This will insert content (from the data parameter) into the story pane.  If isHtml == true
 * then it is assumed that this is not a markdown file and we will not convert it to html from markdown.
 * If this is the first time this function is called, it will create a div element, with an id of
 * infoPaneDynamicContent___info with a class called info-p-content.  If this element contains a class
 * called show then this part of the info-pane will display.
 *
 **/
function populateInfoPaneWithUrlContent(data, isHtml) {
    if(flagDelay){ // Info-pane 'locking' - for duration of the test URL command above (from the moment is starts - until all the delayed commands finish)

        return true;

    }

    // TODO: When this file is replaced, need to delete any event handlers to stop memory leak.
    var infopane;

    //if this is an html file, no need to process it. In future check on security problems.
    if (!isHtml) {
        data = decodeURI(data);
        infopane = converter.makeHtml(data);
    } else {
        infopane = data;
    }
    // this will clean up event handlers
    // TODO:Do i really need this?
    $(".information-pane p").remove();

    // see if we already have a div to shove this in. If not, make it
    if ($("#infoPaneDynamicContent___info").length === 0) {
        var divToWrap = document.createElement("div");
        $(divToWrap).attr({
            "class": "info-p-content",
            id: "infoPaneDynamicContent___info"
        });
        $(".information-pane").append(divToWrap);
    }
    CurrentIdOfInfoPane = "infoPaneDynamicContent___info";
    var dyn = $("#infoPaneDynamicContent___info")[0];

    if (dyn) {
        //Need to remove event handlers from links on this.
        $(dyn).find("a").off();
        // replace data in our special dynamic div in .information-pane with new content
        $(dyn).html(infopane); //for the error you sometimes see here, look here for a fix: https://stackoverflow.com/questions/24639335/javascript-console-log-causes-error-synchronous-xmlhttprequest-on-the-main-thr
        $(".info-p-content").removeClass("show");
        $("#mapDbEditor").hide(); //don't want to see the map db editor while looking at info pane content
        $(dyn).addClass("show");
        $('.ui-layout-pane-east').scrollTop(0); //scroll to top of new content in info pane
        createHoverEventForTaggedWords();
    }
}

/**
 * Function to extract query string values from a URL
 * @param url = Target url eg: href='#/?+++&unoInfo=id'
 * @param query = query string target value eg: unoInfo
 * @return = The value of the query target within the url eg: id
 **/
function getQueryParamFromHrefUrl(url, query) {
    var vars = url.split("&");
    for (var i = 0; i < vars.length; i++) {
        var pair = vars[i].split("=");
        if (pair[0] == query) {
            return pair[1];
        }
    }
    return (false);
}

function populateStoryPaneWithUrlContent(data, isHtml, unoGIdObject) {
     // TODO: When this file is replaced, need to delete any event handlers to stop memory leak.

    var storyPane;
    //if this is an html file, no need to process it. In future check on security problems.
    if (!isHtml) {
        data = decodeURI(data);
        storyPane = converter.makeHtml(data);
    } else {
        storyPane = data;
    }
    //remove event listeners
    $(".narration-pane").find("a").off();
    $(".narration-pane").html(storyPane);
    $(".ui-layout-west").scrollTop(0); //scroll to top
    Slides = $("a.slide");
    SlideSelected = -1;
    createHoverEventForTaggedWords();

    /**
     * Hover Functionality
     * This function loads the long description of a storylink UNO into the infoPane
     **/
     $("#navigation-pane").on('mouseenter', '.slide', function (e) {
        var url = $(this).attr('href');
        var objectId = getQueryParamFromHrefUrl(url, "unoInfo");
        var unoGIdObject = idGroupObject[objectId];
        if (unoGIdObject !== undefined) {
            populateInfoPane(unoGIdObject);
        } else if ( idiagramSvg.database.get(objectId) != undefined ) {
            populateInfoPaneWithMapDbEntry(objectId);
        }

    });

    $(".slide").on("click", function (e) {
        for (var i = 0; i < Slides.length; i++) {
            if (Slides[i] === e.currentTarget) {
                SlideSelected = i;
                $(".selected").removeClass("selected");
                $(Slides.get(SlideSelected)).addClass("selected");
                //TODO: can't we just do this with a couple of lines of jQuery?
                break;
            }
        }
    });
}

function populateInfoPaneWithMapDbEntry(unoId) {
    if(flagDelay){ // Info-pane 'locking' - for duration of the test URL command above (from the moment is starts - until all the delayed commands finish)

        return true;

    }
 
    var uno = idiagramSvg.database.get(unoId)
    uno.content = uno.longDescription
    uno.synopsis = uno.shortDescription
    window.sharedData.selectedUno = uno;
    
    checkLocked({uno: unoId})
  }

/**
 * Called from fileInfo command after calling $.get(infofile, callbackLoadDynamicContent.bind(this, infofile, keyName));
 * Also called from story command where this is called: $.get(infofile, callbackLoadDynamicContent.bind(this, infofile, keyName));
 **/
function callbackLoadDynamicContent(filePath, whichPane, fileContents, status) {
    if (status === "success") {

        // get extension of file.
        var ext = /^data:audio\/([^;,]+);/i.exec(filePath);
        if (!ext) {
            ext = /\.([^.]+)$/.exec(filePath.split('?', 1)[0]);
        }

        if (ext) {
            ext = ext[1].toLowerCase();

            switch (ext) {

                case "hbs": //we got a handlebars file

                    var template = Handlebars.compile(fileContents);

                    //TODO:  !!! Grab any default data from database and insert this here!!!
                    var data = {};
                    var result = template(data);
                    if (whichPane == "infofile" || whichPane == "info" || whichPane == "fileInfo") {
                        /**
                         * Note when we load this in, this may contain a global variable called infoFileData
                         * it contains data that is unique to this particular info file. here is an example:
                         *
                         * var infoFileData = {
                         *     dbEditing : true //let's me know that this allows user to click on a uno and have it populate this form with its row of data from the database
                         * }
                         */
                        populateInfoPaneWithUrlContent(result, true);
                    } else if (whichPane == "storyfile" || whichPane == "story") {
                        LastStoryFile = filePath;
                        populateStoryPaneWithUrlContent(result, true);
                    }
                    break;
                case "txt":
                case "md":
                case "html":
                case "htm":
                    var isHtml = ((ext === "html") || (ext === "htm")) ? true : false; //if true then don't put through decodeURI or markdown
                    if (whichPane == "infofile" || whichPane == "info" || whichPane == "fileInfo") {
                        populateInfoPaneWithUrlContent(fileContents, isHtml);
                    } else if (whichPane == "storyfile" || whichPane == "story") {
                        LastStoryFile = filePath;
                        populateStoryPaneWithUrlContent(fileContents, isHtml);
                    }
                    break;
                default:
                    break;
            }
        }
    } else {
        console.error("Infopane data Error: " + status);
    }
}

/**
 * Called after loading the json of a map form that was created with formBuilder
 **/
function callbackLoadMapEditor(fileContents) {

    //if ($("#mapDbEditor").length === 0) {
    //we don't test for the presence of mapDbEditor because I test to see if the mapEditor file has been loaded before calling this function.
    //So, right now, if we get this this position, this function has never been called.  This is one reason the browser must be refreshed each time a new map
    //svg file is loaded.
    var divToWrap = document.createElement("div");
    $(divToWrap).attr({
        "class": "mapDbEditor",
        id: "mapDbEditor",
        //set style at first to display: none;
        style: "display: none;"
    });
    $(".information-pane").append(divToWrap);
    //Now let the esape button remove this form view without saving. See this under $(window).keydown(function(e)
    //I put this here also because if the focus was on this editor, then hitting escape did not work
    $("#mapDbEditor").keydown(function (e) {
        if (e.keyCode == 27) {
            //escape key pressed
            $("#mapDbEditor").hide();
        }
    });
    var dyn = $("#mapDbEditor")[0];
    if (dyn) {
        $(dyn).html(fileContents);
        $(".info-p-content").removeClass("show");
    }
}

/**
 * This will determine if a variable is an integer
 */
function isNumeric(num) {
    return !isNaN(num);
}

/**
 * check to see there is a question mark at the beginning of a url. Add it if missing. If not, then
 * .address.parameter() adds it in weird ways.
 */
function verifyQuestionMarkInURL(url) {
    if (/^[\/][?]/.test(url) !== true) {
        if (/^\/[^?]/.test(url)) {
            // if we are missing ?, such as /on=
            url = url.replace(/^\//, "\/?");
        } else if (/^[^\/]/.test(url) && /^[^?]/.test(url)) {
            // there must be no leading slash or ?
            url = "\/?" + url;
        } else if (/^[?]/.test(url)) {
            // if there is only a ? then add a slash.
            url = url.replace(/^\?/, "\/?");
        }
    }
    return url;
}

/**
 * used to Returns true if parameterList contains a matching command/value pair
 * Now I want it to return the number of matches, so that if there are DUPLICATES in the url
 * then I know to make it so there is only one.
 */
function isDuplicatedCommandValue(parameterList, command, value) {
    var groupId, key, numberOfDups = 0;
    // var pm = $.address.parameterNames();
    // var pname = $.address.parameter(command);
    for (var i = 0; i < parameterList.length; i++) {
        // command will be something like "open" or "close" or "on" or "off"
        key = Object.getOwnPropertyNames(parameterList[i])[0];
        groupId = parameterList[i][key];
        if (key === command && groupId === value) {
            // return true;
            numberOfDups++;
        }
    }
    return numberOfDups;
}

/**
 * Send in a command and a value and this will add it to the url address bar. It will make sure that it is not
 * duplicated. NOTE: You must worry about setting $.address.history(false) before and after this if need be.
 */
function addCommandToURL(command, value) {
    var parameterList = getURLParameterList($.address.queryString());
    if (parameterList && parameterList.length) {
        if (!isDuplicatedCommandValue(parameterList, command, value)) {
            var myUrlAddress = parameterListArrayToUrlAddress(parameterList);
            $.address.value(verifyQuestionMarkInURL(myUrlAddress + "&" + command + "=" + value));
        }
    }
}

/**
 * This takes an array of parameter objects and creates a new one with commands filtered out that already
 * match the state. i.e., if a command is open=foo and we have <g id="foo" class="open" >, this will remove
 * this command from the parameter list. A new list will be returned. purgeMatchedStatesFromParamList() NOTE:
 * This does NOT test to see if there is a duplicate command in the URL list
 */
 function purgeMatchedStatesFromParamList(parameterList) {
    var command;
    var groupId, groupObject;
    /**
     * this new list will be the same as parameterList, but without redundant commands. If a group is already opened,
     * it won't be opened again. I needed to do it this way so that when we hover off, I can simply reverse what I set,
     * and not reverse something that was set before.
     */

    var newParameterList = []; // look through each key/value pair and then call the isStatusMatch() function to see if it is already set to that.

    for (var i = 0; i < parameterList.length; i++) {
      // command will be something like "open" or "close" or "on" or "off"
      command = Object.getOwnPropertyNames(parameterList[i])[0];
      var rfxtypes = /^(?:\+\+\+|panx|pany|zoom|panzoom|all|animate|play|stop|stopall|openall|closeall|wpane|epane|formInfo|info|fileInfo|unoInfo|story|edit|map|infotext|storytext|maptext|goto|gotoz|run|move|scale|fade|rotate|masteron|masteroff|hlt|unhlt|pz)$/;

      if (rfxtypes.test(command) || typeof window[command] === "function") {
        // insert the "+++" parameter in the new list
        newParameterList.push(parameterList[i]);
        continue;
      }

      var groupIdStr = parameterList[i][command]; //TODO. Make sure there are no other problems with the following code. I am not going to purge on=.someClass or whateverCommand=.someClass

      if (isClass(groupIdStr)) {
        newParameterList.push(parameterList[i]);
        continue;
      } //Quick fix for ch193


      var _iteratorNormalCompletion = true;
      var _didIteratorError = false;
      var _iteratorError = undefined;

      try {
        for (var _iterator = groupIdStr.split(",")[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
          groupId = _step.value;
          groupObject = idGroupObject[groupId]; //If we don't have a valid id or if we not are using unhlt=all
          //TODO: See if the if statement is right.

          if (groupObject === undefined || groupId == "all" && command == "unhlt") {
            console.warn("Error. Unhandled command or id. Command: " + command + " id: " + groupId);
            return null;
          }

          if (!groupObject.isStateMatch(command)) {
            // this state was not already set, so it gets into the parameter list.
            newParameterList.push(_defineProperty({}, command, groupId));
          }
        }
      } catch (err) {
        _didIteratorError = true;
        _iteratorError = err;
      } finally {
        try {
          if (!_iteratorNormalCompletion && _iterator.return != null) {
            _iterator.return();
          }
        } finally {
          if (_didIteratorError) {
            throw _iteratorError;
          }
        }
      }
    }

    return newParameterList;
  }

/**
 * This was called from updateAddress() and in processCommandsInURL(), so I made this function. update the pan
 * and zoom parameters in the URL address. toFixed(x) means we want to only include one x points, else each
 * zoom and pan value has two many decimal points taking up space. It is up to the caller to handle
 * $.address.history(false), etc.
 */
function updatePanInURL(getPz = 0) {
    var s = zoomTiger.getSizes();
    var pan = zoomTiger.getPan();
    // Use absolute pan values - Marshall Clemens 03/07/16
    // The calculation below converts from pixels in the map-pane to points in the SVG
    let params = getURLParameterList($.address.queryString())
    let pz = []

    var relativeX = 0;
    var relativeY = 0;
    if (s.realZoom == 0) {
        relativeX = 0;
        relativeY = 0;
    } else {
        relativeX = ((s.width / 2) - pan.x) / s.realZoom;
        relativeY = ((s.height / 2) - pan.y) / s.realZoom;
    }
    if ( getPz) {
        for (const param of params) {
            if ('pz' in param) {
                pz = param.pz.split(',')

                relativeX = pz.length > 3 ? pz[1] : pz[0]
                relativeX = parseInt(relativeX)
                relativeY = pz.length > 3 ? pz[2] : pz[1]
                relativeY = parseInt(relativeY)
            }
        }
    }
    $.address.parameter("panx", relativeX.toFixed(0));
    $.address.parameter("pany", relativeY.toFixed(0));
}

/**
 * When a user clicks on an object, this will update the address bar with that item. We do this by going
 * through all the items that are clicked on. if doHistory is defined and true, then let this update be
 * recorded in browser history
 * uno is optional. If you send it in then the label associated with it will be used in the browser history
 */
function updateAddress(uno, doHistory) {
    $.address.autoUpdate(false);
    if (doHistory === undefined) doHistory = false;
    if (doHistory === false) {
        $.address.history(false);
    }
    // set title and history value from label field in db
    if (uno !== undefined && uno !== null) {
        var label = getDatabase(uno, "label");
        if (label.length) {
            $.address.title(PageTitle + " " + label);
        } else {
            //uno is not an id, but a title value to save in history
            $.address.title(PageTitle + " " + uno);
        }
    }

    let params = getURLParameterList($.address.queryString())
    let urlWpane = 0
    let urlEpane = 0

    if (! uno) {
        for (const param of params) {
            if ('wpane' in param) {
                urlWpane = param.wpane
            }

            if ('epane' in param) {
                urlEpane = param.epane
            }
        }
    }

    if (zoomTiger !== undefined) {
        updatePanInURL(! uno);

        /**
         * The zoom value needs to be relative to the current window size. zoomRatio is the value of zoom that fits the
         * SVG to the current window size i.e. so a zoom value of 1.0 always fits the full map.
         */
        var zoom = zoomTiger.getZoom();
        var relativeZ = zoom / zoomRatio;

        if (! uno) {
            for (const param of params) {
                if ('pz' in param) {
                    let pz = param.pz.split(',')

                    relativeZ = pz.length > 3 ? pz[3] : pz[2]
                    relativeZ = parseFloat(relativeZ)
                }
            }
        }

        $.address.parameter("zoom", relativeZ.toFixed(2));
    }


    // do left and right panes open or shut
    var state = idiagramUi.myLayout.state;
    var isWestPaneOpen = !state.west.isClosed;
    var isEastPaneOpen = !state.east.isClosed;
    var westCurrentSize = state.west.size;
    var eastCurrentSize = state.east.size;

    $('.toggle-pane-btn-left').addClass('closed')
    if (isWestPaneOpen) {
        $('.toggle-pane-btn-left').removeClass('closed')
    }

    $('.toggle-pane-btn-right').addClass('closed')
    if (isEastPaneOpen) {
        $('.toggle-pane-btn-right').removeClass('closed')
    }

    $.address.parameter("wpane", isWestPaneOpen ? westCurrentSize : urlWpane);
    $.address.parameter("epane", isEastPaneOpen ? eastCurrentSize : urlEpane);

    if ($.address.parameter("story") == undefined && LastStoryFile !== null) {
        $.address.parameter("story", LastStoryFile);
    }

    doHistory ? $.address.history(true) : {};
    $.address.update();
    $.address.autoUpdate(true);
    $.address.history(true);
    idiagramSvg.PreviousUrlAddress = $.address.value(); //added 3/21/17
}

/**
 * There is a variable called defaultURL in the html file that has the default URL if no parameters were sent
 * while loading the page for first time. This populates the address bar with defaultURL NOTE: This is onlly
 * called from processAddress()
 */
function updateAddressWithDefaultURL() {
    if (designerPrefs.defaultURL !== undefined && designerPrefs.defaultURL.length) {
        var result = verifyQuestionMarkInURL(designerPrefs.defaultURL);
        $.address.value(result);
        //$.address.update(); //added this 6/1/2016. Why was this not updating before????
        //Now get rid of story file if we already had one loaded.
        if (LastStoryFile !== null) {
            $.address.parameter("story", "");
        }
        //now lose +++, which probably does not make sense to be here.
        $.address.parameter("+++", "");

        PreviousUrlAddress = $.address.value();
    }
}

function consolidateUrlParameters() {
    $.address.autoUpdate(false);
    $.address.history(false);
    $.address.parameterNames().forEach(function (param) {
      var values = $.address.parameter(param);

      if (Array.isArray(values)) {
        $.address.parameter(param, values.join());
      }
    });
    $.address.update();
    $.address.autoUpdate(true);
    $.address.history(true);
  }

/**
 * handle deep linking. This will look at address bar and mimic the clicking events of one or more objects in
 * the SVG file. toggleStuffOffOn==true, Then toggle tooltip off then on. We don't want to toggle tooltips off
 * and on when the address is coming from outside of the page; only when something is clicked on.
 * makePersistant == true means we want to make the object open that we clicked on.
 */
function processAddress() {
    // make sure svgness is defined
    if (svgness !== undefined) {
        //consolidateUrlParameters();
        var q = getURLParameterList($.address.queryString());

        $.address.autoUpdate(false);
        //I think that q.length is always at least one
        if (q == undefined || q.length < 1) {
            updateAddressWithDefaultURL();
        } else {
            /**
             * See if there is at least some object to work on. If not, I am assuming there are no id's, in which I
             * will use the default URL stored in the web page defaultURL variable, if any.
             */
            var keys = ["+++", "on", "off", "open", "close", "all", "animate", "play", "stop", "openall", "closeall", "stopall",
                "info", "formInfo", "unoInfo", "story", "edit", "infotext", "maptext", "storytext", "map", "goto", "gotoz", "run", "move", "scale", "fade", "rotate",
                "masteron", "masteroff"
            ];
            if (!isKeyInParameterList(q, keys)) {
                updateAddressWithDefaultURL();
                // now we have complete list, polpulate q with the complete list of parameters in the URL
             //   q = getURLParameterList($.address.queryString());
            }
        }
        //TODO: is turning off history the right thing to do here?
        // $.address.history(false);

        // if there is no story defined to stick in .narration-pane then get it from the default defined in html
        //var isStory = $.address.parameter("story");
        //if (isStory == undefined && designerPrefs.storyFile !== undefined && LastStoryFile === null) {
        //    $.address.parameter("story", designerPrefs.storyFile);
        //}
        //$.address.update();
        //idiagramSvg.PreviousUrlAddress = $.address.value();
        //$.address.autoUpdate(true);
        q = getURLParameterList($.address.queryString());
        var scrollToId = $.address.path().replace(/\//g, ''); //this is an id in pane that we should scroll to
        processCommandsInURL(q, scrollToId);
    }
}

/**
 * This will get the id parameter with the highest number (i.e., id4, not id3) in an anchor tag with a class
 * called "tagged." Called from createHoverEventForTaggedWords() and also from somewhere in
 * createHoverEventForTaggedWords() anchorValues contains a list of parameters in the anchor tag, plus other
 * things I may need to return
 */
function getLastIdParameterOnClickedAnchor(thisClickedOnObject, anchorValues) {
    // looks like this: "#/?id0=Accounting_2_"
    var locationString = $(thisClickedOnObject).prop("hash");
    if (locationString !== undefined) {
        locationString = locationString.replace(/.*\#(.*)/, "$1");
        var parameterString = locationString.replace(/.*\?(.*)/, "$1");
        var parameterTokens = parameterString.split("&");
        var parameterList = [];
        for (var j = 0; j < parameterTokens.length; j++) {
            var parameterName = parameterTokens[j].replace(/(.*)=.*/, "$1");
            var parameterValue = parameterTokens[j].replace(/.*=(.*)/, "$1");
            parameterList[parameterName] = parameterValue;
        }
        var q = parameterList;
        anchorValues.parameterList = parameterList;
        anchorValues.locationString = locationString;
        anchorValues.parameterString = parameterString;
        if (q && Object.keys(q).length) {
            var filteredKeyVals = getAllKeysAndValuesStartingWith(q, "unoInfo");
            // grab last id on the list to highlight it.
            var objectId = filteredKeyVals["unoInfo"];
            if (objectId && objectId.length) {
                anchorValues.lastId = objectId;
            } else {
                filteredKeyVals = getAllKeysAndValuesStartingWith(q, "gotoz");
                // grab last id on the list to highlight it.
                objectId = filteredKeyVals["gotoz"];
                if (objectId && objectId.length) {
                    anchorValues.lastId = objectId;
                } else {
                    filteredKeyVals = getAllKeysAndValuesStartingWith(q, "open");
                    // var k = Object.keys(q).length;
                    // grab last id on the list to highlight it.
                    objectId = filteredKeyVals["open"];
                    if (objectId && objectId.length) {
                        anchorValues.lastId = objectId;
                    } else {
                        filteredKeyVals = getAllKeysAndValuesStartingWith(q, "on");
                        // var k = Object.keys(q).length;
                        // grab last id on the list to highlight it.
                        objectId = filteredKeyVals["on"];
                        if (objectId && objectId.length) {
                            anchorValues.lastId = objectId;
                        }
                    }
                }
            }

            // TODO: this is ugly code because I changed it from something else.
            // In hurry
            return objectId != undefined ? objectId : 0;
        } else {
            anchorValues.lastId = 0;
            return 0;
        }
    } else {
        // big error if this is 0. Probably programmer error
        return 0;
    }
}

/**
 * This will allow hovering over certain text to mimic a hovering event on objects in the svg Sample anchor
 * tag in the text that will triggerHandler a hover event: <a
 * href="/#/?id0=SDGs_2_&id1=SDGs_4_&id2=IntegratedSolutions_2_" class="tagged">Integrated Solutions</a> <a
 * href="/#/?id0=Accounting_2_&id1=SDGs_4_&id2=IntegratedSolutions_2_" class="tagged">Accountants & Actuaries</a>
 * #/?id0=Accounting_2_ We need to do this everytime we populate stuff with text, so user can hover over stuff
 * And mimic hovering over objects in svg We only want to load this once for each object id that is loaded, so
 * we don't get multiple events registered, so the parameter, clickedObjectId, helps us track that.
 */
function createHoverEventForTaggedWords() {
    // see if this is already evented
    $("a.tagged:not(.evented),a.hover:not(.evented)")
        .hover(
            function (e) {
                var that = this;
                TextHoverTimer = setTimeout(function () {
                    var anchorValues = {};
                    if (svgness !== undefined) {
                        //Record the state of all elements so you can restore them later.
                        that.savedStateOfWrappedElements = [];
                        getStatesOfAllWrappedElements(that.savedStateOfWrappedElements);
                        // that.isActiveHover means I hovered on this long enough to qualify for it to
                        // process hovering. if this is false on hover off, I do nothing
                        that.isActiveHover = true;
                        // we want to know the current address here so that we can return to what it was before.
                        that.previousUrlAddress = $.address.value();
                        /**
                         * this should not have a clicked class before coming into this hover event. It should get
                         * added only while hovering. I need to take any clicked class off because sometimes we do
                         * not get a hover-off event, and the clicked class doesn't get removed, thus leaving this
                         * in a sort of persistent (open) state
                         * TODO: Is there any need for the if condition below?
                         */
                        if (hasClass(this, "clicked")) {
                            removeClass(this, "clicked");
                        }
                        anchorValues = {};
                        // looks like this:  "#/?id0=Accounting_2_"
                        var locationString = $(that).prop("hash");
                        locationString = locationString.replace(/.*\#(.*)/, "$1");
                        var parameterString = locationString.replace(/.*\?(.*)/, "$1");
                        var parameterList = getURLParameterList(parameterString);
                        // we use that.originalParameterList to store our original unpurged parameter list
                        that.originalParameterList = parameterList;
                        // Let us go through these parameters and see if any are already set to the same state.
                        that.isPlusPlusPlusInParameters = false;
                        if (isKeyInParameterList(parameterList, ["+++"])) {
                            that.isPlusPlusPlusInParameters = true;
                            parameterList = purgeMatchedStatesFromParamList(parameterList);
                        }
                        // We use parameterList when we hover off
                        if (parameterList) {
                            that.parameterList = parameterList;
                            if (that.isPlusPlusPlusInParameters === true && that.parameterList.length < 2) {
                                // if there is nothing in the parameterList but "+++" then don't process this
                            } else {
                                // TODO: Maybe later add code that processes the location path that is right after the hash
                                processCommandsInURL(parameterList, null);
                                // we don't really use anchorValues here
                                anchorValues = {};
                                var thisId = getLastIdParameterOnClickedAnchor(that, anchorValues);
                                if (thisId !== undefined && thisId !== 0 && idGroupObject[thisId] !== undefined) {
                                    populateInfoPane(idGroupObject[thisId]);
                                }
                            }
                        }
                    }
                }.bind(that), TextHoverDelay);
            },
            function () {
                clearTimeout(TextHoverTimer);
                if (this.isActiveHover === true) {
                    this.isActiveHover = false;
                    // TODO: We need to return to previous state, not put everything to default values.
                    if (hasClass(this, "clicked")) {
                        // if user clicked on this, then when hover off, do nothing.
                        // i can tell if he clicked on this because in the click listener singleClk = function()
                        // I add click to this class.
                        removeClass(this, "clicked");
                        return;
                    }

                    //We saved them with mouse hover; this will restore them with hover off.
                    restoreStatesOfAllWrappedElements(this.savedStateOfWrappedElements);
                    $.address.autoUpdate(false);
                    $.address.history(false);
                    $.address.value(this.previousUrlAddress);
                    $.address.update();
                    $.address.history(true);
                    $.address.autoUpdate(true);

                }
            });
    // TODO: separate this out so it is only defined once.
    var singleClk = function (event) {
        // will this just do this automatically? if user clicked on this, then when hover off, do nothing.
        addClass(this, "clicked");

        /**
         * I am also calling this here because I call event.stopImmediatePropagation();
         * and event.preventDefault(); below, which makes it so if there is both a slide and a hover class
         * the slide stuff doesn't get called.  So I call it here.  If they both get called for some reason,
         * there are no bad effects.
         */
        if (hasClass(this, "slide")) {
            for (var i = 0; i < Slides.length; i++) {
                if (Slides[i] === event.currentTarget) {
                    SlideSelected = i;
                    $(".selected").removeClass('selected');
                    $(Slides.get(SlideSelected)).addClass('selected');
                    break;
                }
            }
        }
        if (this.isPlusPlusPlusInParameters === true) {
            // update the url bar
            var purgedParameterList = removeCommandFromParameterListArray(this.originalParameterList, "+++");
            $.address.autoUpdate(false);
            $.address.history(false);
            // add +++ commands to url
            $.address.value(this.previousUrlAddress);
            $.address.update();
            for (var i = 0; i < purgedParameterList.length; i++) {
                // command will be something like "open" or "close" or "on" or "off"
                var command = Object.getOwnPropertyNames(purgedParameterList[i])[0];
                var groupId = purgedParameterList[i][command];
                addCommandToURL(command, groupId);
            }
            // we want to record this in browser history
            $.address.history(true);
            // we want to update this to the address bar
            $.address.update();
            // from now on any time we call $.address.value(foo) it will write the value to the address bar.
            $.address.autoUpdate(true);
        } else if (this.isPlusPlusPlusInParameters === false && this.parameterList.length) {
            // set history and autoupdate to false while we fix up this url
            $.address.history(false);
            $.address.autoUpdate(false);
            // the url gets whatever is in the this.parameterList
            var url = parameterListArrayToUrlAddress(this.parameterList);
            url = verifyQuestionMarkInURL(url);
            $.address.value(url);
            if (!isKeyInParameterList(this.parameterList, ["panx", "panzoom"])) {
                updateAddress(null, false);
            }
            // now we are ready to put this in history
            $.address.history(true);
            $.address.update();
            $.address.autoUpdate(true);
        }
        PreviousUrlAddress = $.address.value();
        event.stopImmediatePropagation();
        event.preventDefault();
    };

    /**
     * This will be called below. This will create click and double-click event for anchor elements that are in
     * pane content.
     */
        // TODO: separate this out so it is only defined once.
    var doubClkFn = function () {
            // I do not use anchorValues in this instance.
            var anchorValues = {};
            var thisId = getLastIdParameterOnClickedAnchor(this, anchorValues);
            var doubleClick = getDatabase(thisId, "ondoubleclick");
            if (doubleClick && doubleClick.length && doubleClick !== "0") {
                var target = getDatabase(thisId, "target");
                // if no target specified open in new window
                target = target.length ? target : "_blank";
                var link = document.createElementNS("http://www.w3.org/1999/xhtml", "a"); //insert
                link.href = doubleClick;
                // if target field is current, need to send in "" to get this to
                // open in current window
                link.target = target === "current" ? "" : target;
                var event = new MouseEvent('click', {
                    'view': window,
                    'bubbles': false,
                    'cancelable': true
                });
                link.dispatchEvent(event);
            }
        };

    // set up trigger for single or double click
    $("a.tagged:not(.evented),a.hover:not(.evented)").each(function () {
        $(this).click(singleClk);
    });
    $("a.tagged:not(.evented),a.hover:not(.evented)").each(function () {
        $(this).keypress(function (e) {
            if (e.key === "d") {
                doubClkFn.call(this);
            }
        });
    });

    // add evented so we don't double-add event handler to this.
    $("a.tagged:not(.evented),a.hover:not(.evented)").addClass('evented');
} // end of createHoverEventForTaggedWords()

/**
 * Loads and svg file
 */
function createNewEmbed(src) {
    var svgFile = src;
    var loadXML = new XMLHttpRequest;

    function handler() {
        if (loadXML.readyState == 4 && loadXML.status == 200) {
            //hide container until we be finished processing svg
            $("#container").css("visibility", "hidden");
            $("#container").html(loadXML.responseText);
            loadXML.onreadystatechange = null; //Had to do this because other svg loadation was calling this, I think.
            // TODO: In future, if we want to load new maps without refreshing page, this will need to be changed.
            loadXML = null;
            svgloaded();
        }
    }

    if (loadXML !== null) {
        loadXML.open("GET", svgFile, true);
        loadXML.onreadystatechange = handler;
        loadXML.send();
    }
}

/**
 * This will take a row from the master db and the override db and merge them
 */
function mergeTwoIdsInMasterAndOverride(master, override) {
    // Merges two (or more) objects, giving the last one precedence
    if (typeof master !== 'object') {
        master = {};
    }
    for (var property in override) {
        if (override.hasOwnProperty(property)) {
            var overrideProperty = override[property];
            if (typeof overrideProperty === 'object') {
                master[property] = mergeMasterAndOverride(master[property], overrideProperty);
                continue;
            }
            if (overrideProperty.length) {
                master[property] = overrideProperty;
            }
        }
    }
    return master;
}

/**
 * Merge master and override databases. Returns a master database that is merged. if there is a field in
 * override that is missing in master, this adds it.
 */
function mergeMasterAndOverride(master, override) {
    for (var i = 0, j = override.length; i < j; i++) {
        var foundStuff = false;
        for (var k = 0, m = master.length; k < m; k++) {
            foundStuff = false;
            if (override[i].id === master[k].id) {
                master[k] = mergeTwoIdsInMasterAndOverride(master[k], override[i]);
                foundStuff = true;
                break;
            }
        }
        if (foundStuff === false) {
            // We never found a match, so add this to master.
            master.push(override[i]);
        }
    }
    return master;
}

/**
 * This is called after getting the master database. This gets the override database, then merges them
 * according to the specs. Basically the we use the data from the master database. The override database will
 * override the data from the master. If master or override collection is not available, this will use the one
 * that is available, if any
 */
function getOverRideDatabase(masterDatabase) {
    /* TODO: Implement the override database junk sometime */
    if (false) {
        $.get("/mapdata/" + designerPrefs.overrideDB, function (data, status) {
            // TODO: Create better error-handling
            if (status === "success") {
                var doc2data = new Map(); //this will contain rows of objects like I had when there was a schema
                for (var i = 0; i < data.length; i++) {
                    doc2data.set(data[i].doc.id, data[i].doc);
                }
                overrideDatabase = doc2data;

                if (masterDatabase !== null) {
                    idiagramSvg.database = database = mergeMasterAndOverride(masterDatabase, overrideDatabase);
                    // free up this memory
                    masterDatabase = null;
                    overrideDatabase = null;
                } else {
                    idiagramSvg.database = database = overrideDatabase;
                }
                // Load svg file
                createNewEmbed(lastEmbedSrc);
            } else {
                if (masterDatabase !== null) {
                    idiagramSvg.database = database = masterDatabase;
                    // Load svg file
                    createNewEmbed(lastEmbedSrc);
                } else {
                    idiagramSvg.database = database = new Map();
                    console.warn("Database Error: " + status);
                    alert("Error fetching map data")
                }
            }
        });
    } else {
        if (masterDatabase !== null) {
            idiagramSvg.database = database = masterDatabase;
            // Load svg file
            createNewEmbed(lastEmbedSrc);
        } else {
            idiagramSvg.database = database = new Map();
            console.error("Database Error.");
        }
    }
}

jQuery.fn.single_double_click = function (single_click_callback, double_click_callback, timeout) {
    return this.each(function () {
        var clicks = 0,
            self = this;
        jQuery(this).click(function (event) {
            clicks++;
            if (clicks == 1) {
                setTimeout(function () {
                    if (clicks == 1) {
                        single_click_callback.call(self, event);
                    } else {
                        double_click_callback.call(self, event);
                    }
                    clicks = 0;
                }, timeout || 350);
            }
        });
    });
};

/**
 * This will tell me if the first character of unoIdOrClass is a dot character, which
 * let's me know I am dealing with a class, not an id of a group element. Returns
 * true if this is a class
 **/
function isClass(unoIdOrClass) {
    return unoIdOrClass.charAt(0) === '.';
}

/**
 * Part of the hasClass() addClass() functions.
 */

var hasClass = function (el, className) {
    return $(el).hasClass(className);

};
var addClass = function (el, className) {
    $(el).addClass(className);

};

function removeClass(el, className) {
    $(el).removeClass(className);
}

/**
 * Finds all members whose names start with str
 */
// TODO: This may need to be changed from starting with to equalling the str value
function getAllKeysAndValuesStartingWith(obj, str) {
    var key, results = [];
    for (key in obj) {
        if (obj.hasOwnProperty(key) && key.indexOf(str) === 0) {
            results[key] = obj[key];
        }
    }
    return results;
}

// FIXME: There are multiple implementations of this functionality. Use URLSearchParams instead.
/**
 * This will return an array of objects containing key/value pairs containing a list of parameters in a URL
 * address. Right now I am requiring just the query string (ie., ?a=b&c=d)
 */
function getURLParameterList(parameterString) {
    var parameterTokens = parameterString.split("&");
    var parameterList = [];
    for (var j = 0; j < parameterTokens.length; j++) {
        var parameterName = parameterTokens[j].replace(/(.*)=.*/, "$1");
        var parameterValue = parameterTokens[j].replace(/.*=(.*)/, "$1");
        var o = {};
        o[parameterName] = parameterValue;
        parameterList[j] = o;
    }
    return parameterList;
}

/**
 * Send in a an array of key names and the parameter array (which looks like: [{key:value},{key:value}]) and
 * this will return true or false to let you know if it is present in the list.
 * Like isDuplicatedCommandValue() but it doesn't care what value keys is set to
 */
function isKeyInParameterList(parameterList, keys) {
    // var key;
    if (parameterList !== undefined && Array.isArray(parameterList) && parameterList.length) {
        var l = parameterList.length;
        for (var k = 0; k < l; k++) {
            // go through the array of key names in keys and see if there is a
            // match. If so return true!
            for (var i = 0; i < keys.length; i++) {
                if (keys[i] === Object.getOwnPropertyNames(parameterList[k])[0]) {
                    return true;
                }
            }
        }
    }
    return false;
}

function getCookie(name) {
    const value = `; ${document.cookie}`;
    const parts = value.split(`; ${name}=`);
    if (parts.length === 2) return parts.pop().split(';').shift().replace('%3D', '=');
}

async  function checkLocked(unoGIdObject) {
    if(flagDelay){ // Info-pane 'locking' - for duration of the test URL command above (from the moment is starts - until all the delayed commands finish)
        return true;
    }
    var requestOptions = {
        method: "GET",
        headers: {
            "X-XSRF-TOKEN": getCookie('XSRF-TOKEN')
        }
    };

    if (window.sharedData.userId !== 'false') {
        $('#locked-loader').show()
        const dbId = isNaN(unoGIdObject) ? getDatabase(unoGIdObject.uno, "dbId") : unoGIdObject
        if (!dbId) {
            return
        }
        window.sharedData.selectedUno.locked = false
        window.sharedData.selectedUno.adminEdit = false
        window.sharedData.selectedUno.loading = true
        let result = await fetch(`/api/checkLocked/uno/${dbId}/${window.sharedData.userId}`, requestOptions)
            .then((response) => response.json())
            .then((result) => {
                window.sharedData.selectedUno = {
                    ...window.sharedData.selectedUno,
                    locked: result.locked,
                    adminEdit: result.admin_edit,
                    loading: false,
                }

                $('#locked-loader').hide()
            })
            .catch((error) => console.log("error", error));

    }
}

/**
 * Populate infopane
 */
 function populateInfoPane(unoGIdObject) {

    if(flagDelay){ // Info-pane 'locking' - for duration of the test URL command above (from the moment is starts - until all the delayed commands finish)
        return true;
    }

    var uno = {
        id: getDatabase(unoGIdObject.uno, "id"),
        dbId: getDatabase(unoGIdObject.uno, "dbId"),
        label: JSON.parse('"'+getDatabase(unoGIdObject.uno, "label")+'"'),
        locked: getDatabase(unoGIdObject.uno, "locked"),
        adminEdit: getDatabase(unoGIdObject.uno, "admin_edit"),
        content: getDatabase(unoGIdObject.uno, "longDescription"),
        synopsis: getDatabase(unoGIdObject.uno, "shortDescription"),
        slideURL: getDatabase(unoGIdObject.uno, "slideURL"),
        classes: getDatabase(unoGIdObject.uno, "classes"),
        clickAction: getDatabase(unoGIdObject.uno, "clickAction"),
        clickFunction: getDatabase(unoGIdObject.uno, "clickFunction"),
        closeFunction: getDatabase(unoGIdObject.uno, "closeFunction"),
        closeURL: getDatabase(unoGIdObject.uno, "closeURL"),
        hoverAction: getDatabase(unoGIdObject.uno, "hoverAction"),
        hoverFunction: getDatabase(unoGIdObject.uno, "hoverFunction"),
        infoPane: getDatabase(unoGIdObject.uno, "infoPane"),
        location: getDatabase(unoGIdObject.uno, "location"),
        offFunction: getDatabase(unoGIdObject.uno, "offFunction"),
        offURL: getDatabase(unoGIdObject.uno, "offURL"),
        onDoubleClick: getDatabase(unoGIdObject.uno, "onDoubleClick"),
        onFunction: getDatabase(unoGIdObject.uno, "onFunction"),
        onURL: getDatabase(unoGIdObject.uno, "onURL"),
        openFunction: getDatabase(unoGIdObject.uno, "openFunction"),
        openURL: getDatabase(unoGIdObject.uno, "openURL"),
        parent: getDatabase(unoGIdObject.uno, "parent"),
        render: getDatabase(unoGIdObject.uno, "render"),
        subtitle: getDatabase(unoGIdObject.uno, "subtitle"),
        symbol: getDatabase(unoGIdObject.uno, "symbol"),
        tooltip: getDatabase(unoGIdObject.uno, "tooltip"),
        ttStyle: getDatabase(unoGIdObject.uno, "ttStyle"),
        unoFrom: getDatabase(unoGIdObject.uno, "unoFrom"),
        unoTo: getDatabase(unoGIdObject.uno, "unoTo"),
        url: getDatabase(unoGIdObject.uno, "url"),
        urlText: getDatabase(unoGIdObject.uno, "urlText"),
        xoffset: getDatabase(unoGIdObject.uno, "xoffset"),
        xpos: getDatabase(unoGIdObject.uno, "xpos"),
        xsize: getDatabase(unoGIdObject.uno, "xsize"),
        yoffset: getDatabase(unoGIdObject.uno, "yoffset"),
        ypos: getDatabase(unoGIdObject.uno, "ypos"),
        ysize: getDatabase(unoGIdObject.uno, "ysize"),
        notes: getDatabase(unoGIdObject.uno, "notes"),
        story: getDatabase(unoGIdObject.uno, "story"),
        voa: getDatabase(unoGIdObject.uno, "voa"),
        mapId: getDatabase(unoGIdObject.uno, "mapId"),

    };
    window.sharedData.selectedUno = uno;
    checkLocked(unoGIdObject)
  }
/**
 * This will return an id with all of the characters after a "_" (including the "_") stripped off the end
 */
function stripId(thisId) {
    if (thisId === undefined) {
        return
    }
    var pos = thisId.indexOf("_");
    var truncatedId = thisId;
    if (pos > -1) {
        truncatedId = thisId.slice(0, pos);
    }
    // now remove stuff after vvv or ooo
    truncatedId = truncatedId.replace(/^ooo.+/, 'ooo');
    truncatedId = truncatedId.replace(/^vvv.+/, 'vvv');
    truncatedId = truncatedId.replace(/^xxx.+/, 'xxx');
    truncatedId = truncatedId.replace(/^ccc.+/, 'ccc');

    return truncatedId;
}

/**
 * Enter an id and field that you want back. This will remove junk from front and end of thisId and look it up
 * in database and return the value. Removes ddd and vvv from front and junk on end that is put there by AI
 */
function getDatabase(thisId, field) {
    // truncate "_" and subsequent characters
    var truncatedId = stripId(thisId);

    // Now loose the ddd or vvv. Do we really need this?

    var row;
    //TODO: sometime handle the merged db of master and override.
    // masterForage.getItem("db_" + designerPrefs.masterDB + "_" + truncatedId, function(err, value, field, callBack, truncatedId) {
    //   if (err) {
    //     console.log('Error getting ' + truncatedId + ' from database.');
    //   }
    //   else {
    //     row = value;
    //     if (row.id !== undefined && row.id === truncatedId && row[field] !== undefined) {
    //       callBack(err, row[field]);
    //       //return row[field];
    //     }
    //   }
    // }.bind(this, field, callBack, truncatedId));
    row = database.get(truncatedId);
    if (row !== undefined && row.id !== undefined && row[field] !== undefined) {
        return row[field];
    }
    return "";
}

function getDatabaseAll(thisId) {
    // truncate "_" and subsequent characters
    var truncatedId = stripId(thisId);

    // Now loose the ddd or vvv. Do we really need this?

    var row = database.get(truncatedId);
    return row;
}

/**
 * after the rotate command (and other tween commands), this will remove the class that makes stuff so it is there,
 * though not seen. It reverses the display:inline command
 **/
function removeZeroOpacity() {
    $("g[id^='viewport']").removeClass("zero-opacity");
}

/**
 * change stuff from display: none; to opacity:0.0 by setting one class & making css rules do the rest
 * This is so that a tween can work when tweening elements that normall have the css style, display: none;
 **/
function addZeroOpacity() {
    $("g[id^='viewport']").addClass("zero-opacity");
}

/**
 * For tweening, this populates selector and resultArray.
 * selector contains the css selector of id's and classes that need to be tweened.
 * resultArray contains the other parameters that are used in various tween commands, such as scale values, etc.
 **/
function getSelectorAndResultArray(customFuncParameters) {
    //first, if browser is firefox or some others, need to change encoded values to normal single quote:
    //here are values to change:  %E2%80%99  or  %60  or %27
    //also %3E is the > character
    //also %20 is a space
    var shiftParams = customFuncParameters;
    shiftParams = shiftParams.replace(/(?:%E2%80%99)|(?:%60)|(?:%27)/g, "'"); //fix single quotes
    shiftParams = shiftParams.replace(/%3E/gi, ">"); //fix single quotes
    shiftParams = shiftParams.replace(/%20/g, " "); //fix single quotes
    shiftParams = shiftParams.replace(/^\s*/, ""); //loose any leading spaces

    //grabs the selector part of this. This is getting a quote, then putting the stuff in between quotes into a
    //result, then matching last quote (whatever quote character we use) then returning the captured group
    var selector = shiftParams.match(/^['’`]([^’'`]+)[’'`]/); //selector may look like: ".step3>.ooo, step3>.vvv>.ccc, .step3>.arclabel"
    var relative;
    if (selector !== undefined && selector !== null) {
        var paramsAfterSelector = shiftParams.slice(selector[0].length);
        if (!paramsAfterSelector.length) {
            //did not get a normal tween parameter, so return parameter that user sent in via fullParameterString
            return {
                selector: customFuncParameters,
                resultArray: null,
                relative: null,
                fullParameterString: customFuncParameters //return parameter that user sent in via fullParameterString
            };
        }
        var resultArray = paramsAfterSelector.split(","); //looks like: ["", "1", "f", "1.3", "1.3"]
        if (resultArray !== undefined && resultArray.length > 2) {
            relative = resultArray[2].toLowerCase() === 't' ? "+=" : "";
            selector[1] = selector[1].replace(/@/g, "#");
        } else {
            //did not get a normal tween parameter, so return parameter that user sent in via fullParameterString
            return {
                selector: shiftParams,
                resultArray: null,
                relative: null,
                fullParameterString: shiftParams //return parameter that user sent in via fullParameterString
            };
        }
        return {
            selector: selector[1],
            resultArray: resultArray,
            relative: relative,
            fullParameterString: shiftParams
        };
    } else {
        //did not get a normal tween parameter, so return parameter that user sent in via fullParameterString
        return {
            selector: shiftParams,
            resultArray: null,
            relative: null,
            fullParameterString: shiftParams
        };
    }
}

/**
 * This is called from case "on": and others where I end up with duplicate on=.class command/values in url
 * This only removes duplicate command/value pairs, and leave only one command/value pairs. It doesn't delete all commands, just
 * duplicate command/value pairs.
 **/
function removeDupCommandValFromUrl(keyName, objectId) {
    var parameterList = getURLParameterList($.address.queryString());
    if (parameterList && parameterList.length) {
        if (isDuplicatedCommandValue(parameterList, keyName, objectId) > 1) {
            removeCommandFromURL(keyName, objectId, true); //take'em all out
            addCommandToURL(keyName, objectId);
        }
    }
}

/**
 * Called from processCommandsInURL() to process pan and zoom commands
 **/
function runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration, gotoZoom) {

    var doZoom = true;
    var doPanning = true;

    if(gotoId != undefined){
        var doPanning = false; //if goto command was called the doPanning is setted to false
    }

    if(gotoZoom != undefined){
        if(gotoZoom == true){
            doZoom = false; //if gotoz command was called the zoom is setted to false
            doPanning = false;
        }
    }
    if (zoom !== undefined && doZoom === true) {
        var relativeZ = zoom * zoomRatio;
        if (panzoomDuration !== undefined) {
            //idiagramSvg.tweenDuration = panzoomDuration;
            $('.svg-pan-zoom_viewport').css('transition','transform ease-out ' + panzoomDuration +'s'); //change css for tweenduration

        }
        zoomTiger.zoom(relativeZ);
    }
    if (panx !== undefined && pany !== undefined && doPanning === true) {
        // Now get the new sizes AFTER we've done the new zoom above.
        var s = zoomTiger.getSizes();
        zoomTiger.getPan();

        /**  Marshall Clemens, 03/07/16
         *  Pan values correspond to absolute coordinates in points (the native Illustrator / SVG units)
         *  e.g. a pan value of 100, 200 will pan the center of the map-pane to 100pt, 200pt on the SVG.
         *  The calculation below converts from points (panx, pany) to pixels (x, y), and the pan
         *  point is offset to the center of the map-pane e.g. (s.width / 2).
         *  This calculation is inverted when getting the pan values and setting them in the URL.
         */
        var x = (s.width / 2) - (panx * s.realZoom);
        var y = (s.height / 2) - (pany * s.realZoom);
        if (panzoomDuration !== undefined) {
            //idiagramSvg.tweenDuration = panzoomDuration;
            $('.svg-pan-zoom_viewport').css('transition','transform ease-out ' + panzoomDuration +'s'); //change css for tweenduration

        }
        zoomTiger.pan({
            x: x,
            y: y
        });

    } else if (doPanning === false && gotoId!= undefined ) { //In other words, if we have a goto or gotoz command

        //we don't want this showing up in the url; only the panx and pany and zoom stuff
        $.address.autoUpdate(false);
        $.address.parameter("goto", "");
        var id$ = $("#" + gotoId)[0];
        if (id$ !== undefined) {
            var ta = id$.getBBox();
            // gotoz - handle zoom stuff if we had a gotoz command
            if (!doZoom) {
                //TODO: Make this a global to speed things up
                var zoomToElement;
                s = zoomTiger.getSizes();

                // Do the Zoom
                // Fit the element to the map-pane width or height depending on the map-pane aspect ratio
                if (((s.width / s.height) / (ta.width / ta.height)) >= 1.0) {
                    // Calculate zoom-to-fill for height
                    // We must adjust for which dimension the zoom ratio was set for
                    if (s.viewBox.height / s.viewBox.width > s.height / s.width) {
                        zoomToElement = ((s.viewBox.height / ta.height) * zoomRatio);
                    } else {
                        zoomToElement = ((s.viewBox.height / ta.height) * zoomRatio) / ((s.viewBox.height / s.viewBox.width) / (s.height / s.width));
                    }
                } else {
                    // Calculate zoom-to-fill for width
                    // We must adjust for which dimension the zoom ratio was set for
                    if (s.viewBox.height / s.viewBox.width > s.height / s.width) {
                        zoomToElement = ((s.viewBox.width / ta.width) * zoomRatio) * ((s.viewBox.height / s.viewBox.width) / (s.height / s.width));
                    } else {
                        zoomToElement = ((s.viewBox.width / ta.width) * zoomRatio);
                    }
                }
                //  do the zooming
                zoomTiger.zoom(zoomToElement);
                // then back off ~ 20% zoom to provide a bit extra space
                zoomTiger.zoomBy(getGlobal("gotozRatio")); //defaults to 0.833333
                // get the new zoom value and normalize to the zoomRatio, then set the URL parameter
                zoomToElement = zoomTiger.getZoom() / zoomRatio;
               // $.address.parameter("zoom", zoomToElement.toFixed(1));
            }

            // Do the panning
            s = zoomTiger.getSizes();
            zoomTiger.getPan();

            x = (s.width / 2) - ((ta.x + (ta.width / 2)) * s.realZoom);
            y = (s.height / 2) - ((ta.y + (ta.height / 2)) * s.realZoom);

            var tweenCallback = function (s) {
                var pan = zoomTiger.getPan();

                // The calculation below converts from pixels in the map-pane to points in the SVG
                var relativeX = 0;
                var relativeY = 0;
                if (s.realZoom !== 0) {
                    relativeX = ((s.width / 2) - pan.x) / s.realZoom;
                    relativeY = ((s.height / 2) - pan.y) / s.realZoom;
                }
                $.address.parameter("panx", relativeX.toFixed(0));
                $.address.parameter("pany", relativeY.toFixed(0));

                //TODO: may need to call this after a few comments if pan values in url are wrong:  updatePanInURL();
                // need to set pan in url. Call it here AFTER you pan.
                $.address.update();
                $.address.autoUpdate(true);
                $.address.history(true);
            };
            if (panzoomDuration !== undefined) {
                //idiagramSvg.tweenDuration = panzoomDuration;
                $('.svg-pan-zoom_viewport').css('transition','transform ease-out ' + panzoomDuration +'s'); //change css for tweenduration

            }
            zoomTiger.pan({
                x: x,
                y: y
            });
            tweenCallback(s);
        } else {
            alert("Bad id in gotoz command: " + gotoId);
        }
    }

}

/**
 * Send in one command and this will pre-pend a +++ and run the command and also add
 * it to the url if it isn't in there.
 */
function processCommandsAndAddToURL(command, id) {
    flagDoNoResetMap = false;

    var addressHistoryState = $.address.history();
    var q = idiagramSvg.getURLParameterList("+++&" + command + "=" + id);
    $.address.history(true);
    idiagramSvg.processCommandsInURL(q);
    idiagramSvg.addCommandToURL(command, id); //openall=id
    $.address.update();
    idiagramSvg.PreviousUrlAddress = $.address.value();
    $.address.history(addressHistoryState);
}

function holdLevels(unoId, levels, currentLevel) {
    if (levels >= currentLevel) {
        currentLevel++;
        var vvv = document.getElementById("vvv" + unoId);
        if (vvv != null) {
            for (let i = 0; i < vvv.children.length; i++) {
                if (vvv.children[i].id !== "") {
                    holdUNO(vvv.children[i].id);
                    // recursively call this function to do the next level(s)
                    holdLevels(vvv.children[i].id, levels, currentLevel);
                }
            }
        }
    }
}

function holdUNO(unoId) {
    let selector = "#" + unoId;
    $(selector).addClass("hold");
    // Work down the chain of immediate UNOs
    selector += " > .uno";
    while ($(selector).length) {
        $(selector).addClass("hold");
        selector += " > .uno";
    }
    selector = "#vvv" + unoId + " > .uno";
    $(selector).addClass("hold");
}


/**
 * Handles parameter commands from URL's from both browser address bar and also anchor tags in text Called by
 * processAddress() and the hover event in createHoverEventForTaggedWords() If scrollToId is not null then it
 * is the path, if any, right after the hash that tells us where to scroll to on the page.
 */
async function processCommandsInURL(q, scrollToId, flagDelayURLCommand) {
    //console.trace();

    flagDoNoResetMap = false;
    if(flagDelay && flagDelayURLCommand == undefined){
        flagDelay = false; //it means that delayed command should be stopped
    }

    while(isRunning && flagDelay == false){
        await sleep(0.1); //wait to finish the last delayed command
    }


    if (!isKeyInParameterList(q, ["hlt", "togglehlt"])) {
        idiagramUtil.undoHlt();
    }

    // Step 1: See if there is a "+++" if there is not, reset svg to everything off.
    if (!isKeyInParameterList(q, ["+++"])) {
        //if we have '---' then handle smart commands. Only close or off uno's that are not in this list as on or open
        if (isKeyInParameterList(q, ["---"])) {
            flagDoNoResetMap = true;

            for (i = 0; i < q.length; i++) {
                keyName = Object.getOwnPropertyNames(q[i])[0]; //keyName is the command name, such as "open"
                q[i][keyName] = q[i][keyName] === undefined ? q[i][keyName] = "" : q[i][keyName];
                if (q[i][keyName] === keyName && keyName !== "+++" && keyName !== "---") {
                    alert("Error. Command without a parameter: " + keyName);
                } else if (!q[i][keyName] && keyName !== "+++" && keyName !== "---") {
                    alert("Error. Command without a parameter: " + keyName);
                }

                var u;
                var unoId;
                var selector;
                var params;
                var theThing;
                var thereIsHlt = false;

                switch (keyName) {
                    case "on":
                        try {
                            // separate the unoID(s) for a multi-UNO open
                            unoId = q[i][keyName];  // the list of comma separate UNOs
                            params = unoId.split(",");

                            for (u = 0; u < params.length; u++) {
                                unoId = params[u];
                                selector = "#" + unoId;
                                $(selector).addClass("hold");
                                // Work down the chain of immediate UNOs
                                selector += " > .uno";
                                $(selector).addClass("hold"); // while ($(selector).length) {
                                    //     $(selector).addClass("hold");
                                    //     selector += " > .uno";
                                    // }
                                selector = "#xxx" + unoId + " > .uno";
                                $(selector).addClass("hold");
                            }
                        } catch (err) {
                            alert("Error. Aborting: " + err);
                            console.warn("Error. Aborting: " + err);
                            return;
                        }
                        break;
                    case "open":
                        try {
                            // separate the unoID(s) for a multi-UNO open
                            unoId = q[i][keyName];  // the list of comma separate UNOs
                            params = unoId.split(",");

                            for (u = 0; u < params.length; u++) {
                                unoId = params[u];
                                selector = "#" + unoId;

                                $(selector).addClass("hold");
                                // Work down the chain of immediate UNOs
                                selector += " > .uno";
                                $(selector).addClass("hold"); // while ($(selector).length) {
                                    //     $(selector).addClass("hold");
                                    //     selector += " > .uno";
                                    // }
                                selector = "#vvv" + unoId + " > .uno";
                                $(selector).addClass("hold");
                            }
                        } catch (err) {
                            alert("Error. Aborting: " + err);
                            console.warn("Error. Aborting:  " + err);
                            return;
                        }
                        break;
                    case "openl":
                        try {
                            // separate the unoID from the other openl parameters
                            unoId = q[i][keyName];
                            params = unoId.split(",");
                            unoId = params[0];
                            var levels = params[1];

                            holdUNO(unoId);
                            // holdLevel() will be called recursively to drill down the levels
                            holdLevels(unoId, levels - 1, 1);
                        } catch (err) {
                            alert("Error. Aborting: " + err);
                            console.warn("Error. Aborting: " + err);
                            return;
                        }
                        break;
                    case "openall":
                        try {
                            unoId = q[i][keyName];
                            theThing = $("#" + unoId);
                            theThing.addClass("hold");
                            theThing.find(".ddd").each(function () {
                                unoId = $(this).attr("id");
                                $("#" + unoId).addClass("hold");
                            });
                        } catch (err) {
                            alert("Error. Aborting: " + err);
                            console.warn("Error. Aborting: " + err);
                            return;
                        }
                        break;
                    default:
                        break;
                }
            }
            //now close and set all to off that does not contain a hold class
            callAllGroups("setNonHoldToClose"); //default state
            callAllGroups("setNonHoldToOff"); //default state
            $(".hold").removeClass("hold"); //Now everything without a hold class is set to off and close, remove everything containing a hold class
        }
        //TODO: Why do we have this? Need better documentation
        else if (!isKeyInParameterList(q, ["all"])) {
            // do not call this stuff while first initializing
            if (idiagramSvg.doTweeningInSvgPanZoom === true) {
                callAllGroups("setAllToClose"); //default state
                callAllGroups("setAllToOff"); //default state
            }

            //Set just the top groups to off
            $('.svg-pan-zoom_viewport>g[id]').each(function () {
                var id = $(this).attr("id");
                if (id in idGroupObject) {
                    idGroupObject[id].setThisToOff();
                }
            });
        }
    }
    // [ "on", "panx", "pany", "zoom", "wpane", "epane" ]
    var objectId;
    var gotoId; //for goto
    var state = idiagramUi.myLayout.state;
    var isWestPaneOpen = !state.west.isClosed;
    var isEastPaneOpen = !state.east.isClosed;
    var panx, pany, zoom, panzoomDuration;
    var doPanning = true; //doPanning set to false if we are processing a goto or gotoz command
    var doZoom = true; //doZoom is later set to false if gotoz command is present, because we handle zoom there.
    var gotoZoom = false;
    var wPaneCommandHappened;
    //if we got a wpane or epane command, then pause code while we wait for panes to open and close
    var ePaneCommandHappened = wPaneCommandHappened = false;
    // Step 2: step through all parameters and follow the commands in order.
    // q is an array of objects, like this: [{key:value},{key:value}]). We want to look at the name of the key


    var timerest = 0

    for (var i = 0; i < q.length; i++) {

        var keyName = Object.getOwnPropertyNames(q[i])[0];
        q[i][keyName] = q[i][keyName] === undefined ? q[i][keyName] = "" : q[i][keyName];
        if (q[i][keyName] === keyName && keyName !== "+++" && keyName !== "---") {
            alert("Error. Command without a parameter: " + keyName);
        } else if (!q[i][keyName] && keyName !== "+++" && keyName !== "---") {
            alert("Error. Command without a parameter: " + keyName);
        }
        const timeToWaitBeforeDoingGoto = 1005;
        switch (keyName) {
            case "+++":
                flagDoNoResetMap = true;

            case "---":
                // we handled this above
                break;
            case "d":
                isRunning = true;

                flagDelay = true;

                var timerest = q[i][keyName];


                //executo os comandos predecessores ao delay

                runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration, timerest);


                if (timerest !== undefined) {

                    await sleep(timerest); //executo o delay

                }


                if(flagDelay == false){//it means that other command was trigered while delay execution
                    isRunning = false; //set flag for execution of url commands to off
                    return false; //stop execution of delayed command

                }
                flagDelay = false; //unlocking info-pane after run all delay commands

                break;
            case "all":
                var command = q[i][keyName];
                switch (command) {
                    case "on":
                        callAllGroups("setAllToOn");
                        break;
                    case "open":
                        callAllGroups("setAllToOpen"); //this also calls addPermanent()
                        break;
                    case "off":
                        callAllGroups("setAllToOff");
                        break;
                    case "close":
                        callAllGroups("setAllToClose");
                        break;
                    default:
                        // default code block
                        alert("Bad command: all=" + command);
                        break;
                }
                break;
            case "on":
                flagDoNoResetMap = true;

                // separate the unoID(s) for a multi-UNO on
                unoId = q[i][keyName];  // the list of comma separate UNOs
                params = unoId.split(","); // Sort IDs into hierarchical order so parents are triggered first



                onUno(unoId,params);
                break;
                flagDelay = false; //unlocking info-pane after run all delay commands

            case "off":
                // separate the unoID(s) for a multi-UNO off
                unoId = q[i][keyName];  // the list of comma separate UNOs
                params = unoId.split(",");

                for (u = 0; u < params.length; u++) {
                    objectId = params[u];
                    removeCommandFromURL("on", objectId, true); //take out "on" commands for this id, since it usually makes no sense to have them in the url
                    removeDupCommandValFromUrl(keyName, objectId); //remove duplicate of this key/value from url
                    $.address.update(); //update the url
                    if (objectId in idGroupObject) {
                        idGroupObject[objectId].setThisToOff(true);
                    } //see if this is a class. If so loop through all id's that have this class
                    else if (isClass(objectId)) {
                        $(svgness)
                            .find(objectId).each(function (i) {
                            var unoId = $(this).attr("id");
                            removeCommandFromURL("on", unoId, true); //take out conflicting commands for this id, since it usually makes no sense to have them in the url
                            if (unoId in idGroupObject) {
                                idGroupObject[unoId].setThisToOff();
                            }
                        });
                    } else {
                        alert("Bad id in off command: " + objectId);
                    }
                }
                break;
            case "open":
                if(!flagDoNoResetMap){

                    callAllGroups("setAllToClose");

                    flagDoNoResetMap = true;

                }
                // separate the unoID(s) for a multi-UNO open
                unoId = q[i][keyName];  // the list of comma separate UNOs
                params = unoId.split(",");

                openUno(unoId, params);

                break;
            case "closeall":
            case "close":
                // separate the unoID(s) for a multi-UNO close
                unoId = q[i][keyName];  // the list of comma separate UNOs
                params = unoId.split(",");

                for (u = 0; u < params.length; u++) {
                    objectId = params[u];
                    removeCommandFromURL("open", objectId, true); //take out "open" commands for this id, since it usually makes no sense to have them in the url
                    removeDupCommandValFromUrl(keyName, objectId); //remove duplicate of this key/value from url
                    var parameterList = getURLParameterList($.address.queryString());
                    if (isDuplicatedCommandValue(parameterList, "openall", unoId)) {
                        removeCommandFromURL("openall", unoId, true); //take'em all out
                    }
                    $.address.update(); //update the url
                    if (objectId in idGroupObject) {
                        idGroupObject[objectId].setThisToClose(true);
                    } //see if this is a class. If so loop through all id's that have this class
                    else if (isClass(objectId)) {
                        //remove duplicated off commands. I think we only need to do this for class; id's are handled elsewhere.
                        removeDupCommandValFromUrl(keyName, objectId);
                        $(svgness)
                            .find(objectId).each(function (i) {
                            unoId = $(this).attr("id");
                            removeCommandFromURL("open", unoId, true); //take out conflicting commands for this id, since it usually makes no sense to have them in the url
                            if (unoId in idGroupObject) {
                                idGroupObject[unoId].setThisToClose(true);
                            }
                        });
                    }
                }
                break;
            // Case to enable more than one UNO
            case "openall":
                objectId = q[i][keyName];

                removeDupCommandValFromUrl(keyName, objectId); //remove duplicate of this key/value from url
                $.address.update(); //update the url

                params = objectId.split(",");

                for (u = 0; u < params.length; u++) {
                    if (params[u] in idGroupObject) {
                        idGroupObject[params[u]].parentOpenChildren();
                    }
                }
                break;
            // Case to enable more than one UNO
            case "panx":
                panx = q[i][keyName];

                break;
            case "pany":
                pany = q[i][keyName];

                break;
            case "zoom":
                zoom = q[i][keyName];
                zoom = zoom ? zoom : "1.0";
                break;
            case "panzoom": //panzoom = duration, xpos, ypos, zoom
                //the parameters are in this array: duration=0,xpos = 1, ypos=2,zoom=3
                shiftParams = q[i][keyName];
                autoUpdate = $.address.autoUpdate();
                $.address.autoUpdate(false);
                $.address.parameter("panzoom", ""); //remove all these commands?
                $.address.parameter("panzoom", shiftParams); //add just this back in
                resultArray = shiftParams.split(",");
                if (resultArray !== undefined && resultArray.length > 0) {
                    panzoomDuration = resultArray[0];
                    panx = resultArray[1];
                    pany = resultArray[2];
                    zoom = resultArray[3];
                }
                $.address.autoUpdate(autoUpdate);
                break;
            case "pz": //panzoom = duration, xpos, ypos, zoom
                //the parameters are in this array: duration=0,xpos = 1, ypos=2,zoom=3
                shiftParams = q[i][keyName];
                autoUpdate = $.address.autoUpdate();
                $.address.autoUpdate(false);
                $.address.parameter("pz", ""); //remove all these commands?
                $.address.parameter("pz", shiftParams); //add just this back in
                resultArray = shiftParams.split(",");
                if (resultArray !== undefined && resultArray.length > 0) {

                    panx = resultArray[0];
                    pany = resultArray[1];
                    zoom = resultArray[2];
                    if(resultArray[3] != undefined){
                        panzoomDuration = resultArray[3];
                    }
                }
                $.address.autoUpdate(autoUpdate);
                break;
            case "epane":
                var epane = q[i][keyName];
                if (epane && (epane.toLowerCase() === "close" || epane.toLowerCase() === "closed" || epane === "0")) {
                    $('.toggle-pane-btn-right').addClass('closed')

                    if (isEastPaneOpen) {
                        ePaneCommandHappened = true;
                        idiagramUi.myLayout.close("east");
                    }
                } else {
                    // if epane says "open" it needs to be open now if it is not already
                    if (isNumeric(epane)) {
                        newSize = epane;
                        newSize = newSize < state["east"].minSize ? state["east"].minSize : newSize; //can't be less than minSize
                        newSize = newSize > state["east"].maxSize ? state["east"].maxSize : newSize;
                        epane = newSize;
                        ePaneCommandHappened = epane !== state["east"].size; //don't wait to process zoom/pan command if wpane size is different

                        $('.toggle-pane-btn-right').removeClass('closed')
                        idiagramUi.myLayout.open("east");
                        idiagramUi.myLayout.sizePane("east", +epane);
                    } else {
                        if (epane && isEastPaneOpen) {
                        } else {
                            $('.toggle-pane-btn-right').removeClass('closed')
                            ePaneCommandHappened = true;
                            idiagramUi.myLayout.open("east");
                        }
                    }
                }
                break;
            case "wpane":
                var wpane = q[i][keyName];
                if (wpane && (wpane.toLowerCase() === "close" || wpane.toLowerCase() === "closed" || wpane === "0")) {
                    $('.toggle-pane-btn-left').addClass('closed')
                    if (isWestPaneOpen) {
                        wPaneCommandHappened = true;
                        idiagramUi.myLayout.close("west");
                    }
                } else {
                    // it needs to be open now if it is not already see if this is an integer. If it is then open this to that
                    // width, user can send in wpane=open or wpane=300
                    if (isNumeric(wpane)) {
                        var newSize = wpane;
                        newSize = newSize < state["west"].minSize ? state["west"].minSize : newSize; //can't be less than minSize
                        newSize = newSize > state["west"].maxSize ? state["west"].maxSize : newSize;
                        wpane = newSize;
                        wPaneCommandHappened = wpane !== state["west"].size; //don't wait to process zoom/pan command if wpane size is different
                        $('.toggle-pane-btn-left').removeClass('closed')

                        idiagramUi.myLayout.open("west");
                        idiagramUi.myLayout.sizePane("west", +wpane);
                    } else {
                        if (wpane && isWestPaneOpen) {
                        } else {
                            $('.toggle-pane-btn-left').removeClass('closed')
                            wPaneCommandHappened = true;
                            idiagramUi.myLayout.open("west");
                        }
                    }
                }
                break;

            case "animate":
                var animateParams = q[i][keyName];
                // make sure syntax is correct. Can have zero or more spaces before and after the comma: foo,play
                resultArray = animateParams.match(/(\w+)(?:\s)*(?:%20)*,(?:\s)*(?:%20)*(\w+)/);
                if (resultArray !== undefined && resultArray.length > 0) {
                    present.segmentCommand(resultArray[1], resultArray[2]);
                }
                //take out annoying duplicate animation commands
                $.address.history(false);
                $.address.parameter("animate", "");
                $.address.history(true);
                break;

            case "play":
                //example: play=thisSegmentName - plays the segment from the start
                present.callAllAnimations("stop");
                var segmentName = q[i][keyName];
                present.segmentCommand(segmentName, "play");

                //take out annoying duplicate animation commands
                $.address.history(false);
                $.address.parameter("play", "");
                $.address.history(true);
                break;

            case "stop":
            case "stopall":
                // MyTriggerAllClass.stopAll();
                //stop an animation that is playing
                present.callAllAnimations("stop");
                break;

            case "fileInfo":
                infofile = q[i][keyName];
                /**
                 * delete all dup commands but this one.
                 **/
                autoUpdate = $.address.autoUpdate();
                $.address.autoUpdate(false);
                $.address.parameter("fileInfo", ""); //remove all these commands?
                $.address.parameter("fileInfo", infofile); //add just this back in
                $.address.autoUpdate(autoUpdate);
                if (infofile !== undefined && infofile !== null) {
                    $.get(infofile, callbackLoadDynamicContent.bind(this, infofile, keyName));
                    $.address.update(); //update the url
                }
                break;

            case "formInfo": //the form ‘formName’ must be stored in the form database specified in the map’s config.json file: "formDB": "theFormDB",

                var forminfo = q[i][keyName];
                /**
                 * delete all dup commands but this one.
                 **/
                var autoUpdate = $.address.autoUpdate();
                $.address.autoUpdate(false);
                $.address.parameter("formInfo", ""); //remove all these commands?
                $.address.parameter("formInfo", forminfo); //add just this back in
                $.address.autoUpdate(autoUpdate);
                if (forminfo !== undefined && forminfo !== null) {
                    unoLabel = {
                        label: "" //This label only applies when this is associated with a UNO as in populateInfoPane() when loaded in via longDescription
                    };
                    //------------------------------------------------------------------
                    //formApi is an endpoint in Node.js that handles this call and returns this html form data so that we can insert it into the info pane
                    //We are sending in three parameters. Two are the form name and the collection that the form is found in. The unoLabel
                    //is another way to store parameters, and contains the label which inserted into the form.
                    $.get("/formApi/" + forminfo + "/" + getGlobal("formDB"), unoLabel, function (data, status) {
                        // TODO: Create better error-handling
                        if (status === "success") {
                            populateInfoPaneWithUrlContent(data, true);
                            $.address.update(); //update the url
                        }
                    });
                }
                break;
            case "unoInfo":
            case "info":
                objectId = q[i][keyName];
                var unoGIdObject = idGroupObject[objectId];
                if (unoGIdObject !== undefined) {
                    populateInfoPane(unoGIdObject);
                } else if (idiagramSvg.database.get(objectId) != undefined) {
                    populateInfoPaneWithMapDbEntry(objectId);
                } else {
                    console.error("Cannot fetch info for unknown unoId: " + objectId);
                }

                break;
            case "story":
                var infofile = q[i][keyName];
                //This will add dynamic content to the story pane, which is the left pane

                if (infofile !== undefined && infofile !== null && LastStoryFile !== null && LastStoryFile === infofile) {
                    break; //if we already have the same story content loaded in the story pane then don't load it again!!!
                }
                if (infofile !== undefined && infofile !== null) {

                    $.get(infofile, callbackLoadDynamicContent.bind(this, infofile, keyName));
                }

                // load this. If it is a text file, put it through the mardown
                // converter first.

                // we will keep this in memory if it is not already loaded. In case we keep
                // loading in a bunch of image files. (??? really?)
                // step 1 load in file
                // is it a markdown? -- yes format it
                // insert it in default dev for this and display it.

                break;

            case "edit": //edit map database. Looks like edit=unoid. Use this so you don't have to click on an uno to edit it.
                //This version of edit will get map from form database instead of a hard copy of a map .hbs file
                var unoIdToEdit = q[i][keyName]; //if true then start the map db editor when clicking on a uno

                var mapForm = getGlobal("mapForm");
                if (mapForm === undefined || mapForm === null) {
                    mapForm = "mapDbEditor";
                    // console.error("designerPrefs is missing mapForm definition. Using default mapDbEditor");
                }
                var unoLabel = {
                    label: getDatabase(unoIdToEdit, "label")
                };

                $.get("/mapDbEditor/" + mapForm + "/" + "mapForm", unoLabel, function (unoIdToEdit, data, status) {
                    // TODO: Create better error-handling
                    if (status === "success") {
                        //--------------------------------------------------------
                        $("#mapDbEditor").remove(); //remove this if it was created earlier
                        callbackLoadMapEditor(data);
                        mapDbApi.getRowAndUpdateMapDbForm(unoIdToEdit);
                    } else {
                        console.error("data data Error: " + status);
                    }
                }.bind(this, unoIdToEdit));
                break;

            case "infotext":
                var infotext = q[i][keyName];
                if (infotext !== undefined && infotext !== null) {
                    populateInfoPaneWithUrlContent(infotext, false);
                }
                break;
            case "maptext":
                alert("maptext code not yet completed.");
                break;
            case "storytext":
                alert("storytext will not be a valid command.");
                break;
            case "map":
                alert("map code not yet completed.");
                break;
            case "goto":
                gotoId = q[i][keyName];
                doPanning = false;
                 // separate the unoID(s) for a multi-UNO open
                params = gotoId.split(",");

                onUno(gotoId, params);

                break;
            case "gotoz":
                gotoId = q[i][keyName];
                doPanning = false;
                doZoom = false; // we will do zoom while we handle the gotoz stuff
                gotoZoom = true;
                params = gotoId.split(",");
                onUno(gotoId, params);
                break;
            case "move":
                shiftParams = q[i][keyName];
                // i.e:run=move, 'Steps, @AD'2,f,160,160
                // make sure syntax is correct. Can have zero or more spaces before
                // and after the comma: foo,play
                selectorAndResultArray = getSelectorAndResultArray(shiftParams);
                if (selectorAndResultArray !== null) {
                    var _selector = selectorAndResultArray.selector;
                    var _resultArray = selectorAndResultArray.resultArray;
                    var relative = selectorAndResultArray.relative;
                    addZeroOpacity(); //change stuff from display: none; to opacity:0.0 by setting one class & making css rules do the rest
                    gsap.gsap.to(_selector, _resultArray[1], {
                        x: relative + _resultArray[3],
                        y: relative + _resultArray[4],
                        onComplete: removeZeroOpacity
                    }); //groupId,duration,x,y
                }
                break;
            case "scale":
                //So we could issue commands with a space between the classes, in order to tween multiple things at once.  Such as:
                // &scale=.step3 .step4,1,f,1.5,1.5
                //scale=unoid or .class,duration,relative(t or f),scaleX, scaleY
                shiftParams = q[i][keyName];
                selectorAndResultArray = getSelectorAndResultArray(shiftParams);
                if (selectorAndResultArray !== null) {
                    var _selector2 = selectorAndResultArray.selector;
                    var _resultArray2 = selectorAndResultArray.resultArray;
                    var _relative = selectorAndResultArray.relative;
                    addZeroOpacity(); //change stuff from display: none; to opacity:0.0 by setting one class & making css rules do the rest
                    gsap.gsap.to(_selector2, _resultArray2[1], {
                        transformOrigin: "50% 50%",
                        scaleX: _relative + _resultArray2[3],
                        scaleY: _relative + _resultArray2[4],
                        onComplete: removeZeroOpacity
                    });
                }
                break;
            case "fade":
                //fade=unoid or .class,duration,relative,opacity i.e. fade='.myClass',1,f,0
                shiftParams = q[i][keyName];
                selectorAndResultArray = getSelectorAndResultArray(shiftParams);
                if (selectorAndResultArray !== null) {
                    var _selector3 = selectorAndResultArray.selector;
                    var _resultArray3 = selectorAndResultArray.resultArray;
                    var _relative2 = selectorAndResultArray.relative;
                     addZeroOpacity(); //change stuff from display: none; to opacity:0.0 by setting one class & making css rules do the rest
                     gsap.gsap.to(_selector3, _resultArray3[1], {
                        opacity: _relative2 + _resultArray3[3],
                        onComplete: removeZeroOpacity
                    });
                }
                break;
            case "rotate":
                //rotate=unoid  | class,duration,relative,angle
                var shiftParams = q[i][keyName];

                selectorAndResultArray = getSelectorAndResultArray(shiftParams);
                if (selectorAndResultArray !== null) {
                    var _selector4 = selectorAndResultArray.selector;
                    var _resultArray4 = selectorAndResultArray.resultArray;
                    var _relative3 = selectorAndResultArray.relative;
                    addZeroOpacity(); //change stuff from display: none; to opacity:0.0 by setting one class & making css rules do the rest
                    gsap.gsap.to(_selector4, _resultArray4[1], {
                        transformOrigin: "50% 50%",
                        rotation: _relative3 + _resultArray4[3],
                        onComplete: removeZeroOpacity
                    });
                } else {
                    alert("Bad selector: " + shiftParams);
                }
                break;

            case "run": //this will handle dynamic functions. It comes in looking like this: run=functionName, p1, p2, …
                var runParams = q[i][keyName];
                //see if this is a dynamic function found in user's .js file, such as customstuff.js loaded in from html file
                // make sure syntax is correct. Can have zero or more spaces before and after the comma: foo,play
                var resultArray = runParams.match(/^([^,]+),?(.+)/);
                if (resultArray !== undefined && resultArray.length > 0) {
                    customFunction = window[resultArray[1]]; //custom function, such as move or fade
                    if (typeof customFunction === "function") {
                        selectorAndResultArray = getSelectorAndResultArray(resultArray[2]);
                        customFunction.call(this, selectorAndResultArray);
                    } else {
                        alert("Bad run function: " + keyName);
                    }
                }
                break;
            case "masteron":
                idiagramSvg.messageServer = true; //turns this into the socket message server
                break;
            case "masteroff":
                idiagramSvg.messageServer = false; //turns off socket message server
                break;
            // Case to enable more than one UNO
            case "togglehlt":
                objectId = q[i][keyName];

                params = objectId.split(",");

                for (u = 0; u < params.length; u++) {
                    selectorAndResultArray = getSelectorAndResultArray(params[u]);
                    thereIsHlt = idiagramUtil.togglehlt(selectorAndResultArray);
                }
                break;
            // Case to enable more than one UNO
            case "hlt":
                objectId = q[i][keyName];

                params = objectId.split(",");

                for (u = 0; u < params.length; u++) {
                    selectorAndResultArray = getSelectorAndResultArray(params[u]);
                    thereIsHlt = idiagramUtil.hlt(selectorAndResultArray);
                }

                removeCommandFromURL("hlt", q[i][keyName], true);
                 //take out conflicting commands for this id, since it usually makes no sense to have them in the url
                break;
            // Case to enable more than one UNO
            case "unhlt":
                objectId = q[i][keyName];

                params = objectId.split(",");

                for (u = 0; u < params.length; u++) {
                    selectorAndResultArray = getSelectorAndResultArray(params[u]);
                    idiagramUtil.unhlt(selectorAndResultArray);
                }
                break;
            default:
                var customFunction = window[keyName]; //custom function, such as move or fade
                if (typeof customFunction === "function") {
                    //resultArray.shift(); //remove the first element, which is the name of the function, so we can send in the parameters.
                    var selectorAndResultArray = getSelectorAndResultArray(q[i][keyName]);
                    customFunction.call(this, selectorAndResultArray);
                } else
                    alert("Bad command in URL: " + keyName);
                break;
        }

        runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration,gotoZoom); //to handle with delay, we will execute the pan zoom commands after each iteraction

    //cleaning variables after iteraction

       if(panx != undefined && pany != undefined &gotoId != undefined){//the panx and pany should be used together, so we just clean when both are setted
        panx = undefined ;
        pany = undefined;
       }
       zoom = undefined;
       doPanning = undefined;
       doZoom = undefined;
       gotoId = undefined;
       panzoomDuration = undefined;
       gotoZoom = false;
    }

    function onUno(unoId,params){
        params.sort(function (a, b) {
            if (!(a in idGroupObject)) {
              return 1;
            }

            if (!(b in idGroupObject)) {
              return -1;
            }

            return $(idGroupObject[a].gIdObject).parents().length - $(idGroupObject[b].gIdObject).parents().length;
          });

        for (u = 0; u < params.length; u++) {
            objectId = params[u];
            removeCommandFromURL("off", objectId, true); //take out "off" commands for this id, since it usually makes no sense to have them in the url
            removeDupCommandValFromUrl(keyName, objectId); //remove duplicate of this key/value from url
            $.address.update(); //update the url
            if (objectId in idGroupObject) {
                idGroupObject[objectId].setAllToOn(); //actually this only turns this on but it also sets the permanent class
            } else
                //see if this is a class. If so loop through all id's that have this class
            if (isClass(objectId)) {
                $(svgness)
                    .find(objectId).each(function (i) {
                    var unoId = $(this).attr("id");
                    //take out conflicting commands for this id, since it usually makes no sense to have them in the url
                    removeCommandFromURL("off", unoId, true);
                    if (unoId in idGroupObject) {
                        idGroupObject[unoId].defaultSetThisToOn();
                    }
                });
            } else {
                alert("Bad id in on command: " + objectId);
            }
        }
    }
    /**
     * responsable of open an single uno
     */
    function openUno(unoId, params){


         for (u = 0; u < params.length; u++) {
             objectId = params[u];
             removeCommandFromURL("close", objectId, true); //take out "close" commands for this id, since it usually makes no sense to have them in the url
             removeDupCommandValFromUrl(keyName, unoId); //remove duplicate of this key/value from url
             $.address.update(); //update the url

             if (objectId in idGroupObject) {
                 idGroupObject[objectId].setThisToOpen();
                 idGroupObject[objectId].addPermanent();
             } //see if this is a class. If so loop through all id's that have this class
             else if (isClass(objectId)) {
                 $(svgness)
                     .find(objectId).each(function (i) {
                     var unoId = $(this).attr("id");
                     removeCommandFromURL("close", unoId, true); //take out conflicting commands for this id, since it usually makes no sense to have them in the url
                     if (unoId in idGroupObject) {
                         idGroupObject[unoId].setThisToOpen();
                         idGroupObject[unoId].addPermanent();
                     }
                 });
             } else {
                 alert("Bad id in open command: " + objectId);
             }
         }
    }

    //flagDelay = false; //unlocking info-pane after run all delay commands
    isRunning = false; //set flag for execution of url commands to off

    //Now remove the tween commands from url. If there are, say multiple rotate commands, then we can't do this before
    //we are finished looping through all the commands, so we do it here.
    var addressAutoUpdateState = $.address.autoUpdate();
    var addressHistoryState = $.address.history();
    $.address.history(false);
    $.address.parameter("rotate", ""); //remove all these commands from url
    $.address.parameter("edit", ""); //remove all these commands from url
    $.address.parameter("fade", ""); //remove all these commands from url
    $.address.parameter("scale", ""); //remove all these commands from url
    $.address.parameter("move", ""); //remove all these commands
    $.address.parameter("stop", ""); //remove all these commands from url
    $.address.parameter("stopall", ""); //remove all these commands

    $.address.parameter("masteron", ""); //remove all these commands
    $.address.parameter("masteroff", ""); //remove all these commands
    $.address.parameter("closeall", ""); //remove all these commands
    $.address.history(addressHistoryState);
    $.address.autoUpdate(addressAutoUpdateState);

    if (ePaneCommandHappened === false && wPaneCommandHappened === false) {
        runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration);
    } else {
        //FIXME: understand why this code was avoided for e/w pane clicks
        runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration);
        $(window).on("panesResized", function (zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration) {
            $(window).off("panesResized");
            runPanZoomCommands(zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration);
        }.bind(this, zoom, panx, pany, doPanning, doZoom, gotoId, panzoomDuration));
    }

    /**
     * If there is a tag in url that points to an anchor, this will jump to it.
     */
    // This comes in looking like /foo/ The following removes the slashes
    if (scrollToId && scrollToId.length) {
        // I use .info-p-content.show because there are other div's in the east pane that are invisible
        try {
            theThing = $("#" + scrollToId);
            if (theThing.length) {
                $('.ui-layout-west,.info-p-content.show').animate({
                        scrollTop: theThing.offset().top
                    },
                    'slow');
            }
        } catch (err) {

        }
    }
}

//This will be called after pan and zoom happens.  This is assigned via onPan and onZoom options
function resetDuration() {
    setTimeout(
        function () {
            idiagramSvg.tweenDuration = tweenDuration; //set back to global
            $('.svg-pan-zoom_viewport').css('transition','transform ease-out ' + idiagramSvg.tweenDuration +'s'); //change css for tweenduration

        }, 500);
}

// iterates through all objects and calls the function in functionName
function callAllGroups(functionName, args) {
    var x;
    var fn;
    x = null;
    for (x in idGroupObject) {
        fn = idGroupObject[x][functionName];
        if (typeof fn === 'function') {
            idGroupObject[x][functionName](args);
        }
    }
}

function removeCommandFromParameterListArray(parameterList, key) {
    if (parameterList !== null) {
        var l = parameterList.length;
        var newList = [];
        for (var k = 0; k < l; k++) {
            // look at key and see if there is a match. If not push that to
            // new array because we only want those that do not match
            if (key !== Object.getOwnPropertyNames(parameterList[k])[0]) {
                newList.push(parameterList[k]);
            }
        }
        return newList;
    } else return [];
}

/**
 * Send in an array of parameters for a url with commands, and this will turn it into a url string for
 * inserting into url bar with $.address.value( )
 */
function parameterListArrayToUrlAddress(parameterList) {
    var urlAddressText = "";
    var command;
    var l = parameterList.length;
    if (l) {
        command = Object.getOwnPropertyNames(parameterList[0])[0];
        urlAddressText += command + "=" + parameterList[0][command];
    }
    for (var k = 1; k < l; k++) {
        command = Object.getOwnPropertyNames(parameterList[k])[0];
        urlAddressText += "&" + command + "=" + parameterList[k][command];
    }
    return urlAddressText;
}

/**
 * Remove command from a string that came from  $.address.value();
 * In the first case for this, I need to remove panx from the global, PreviousUrlAddress
 **/
function removeCommandFromAddressDotValueString(urlAddressValue, command) {
    //save off the current state of these, then set to false then put urlAddressValue in address then use library to remove command.
    //I remove all commands that equal the command parameter
    var addressAutoUpdateState = $.address.autoUpdate();
    var addressHistoryState = $.address.history();
    var currentAddressValue = $.address.value();
    var returnValue = null;
    $.address.autoUpdate(false);
    $.address.history(false);
    $.address.value(urlAddressValue);
    $.address.parameter(command, "");
    returnValue = $.address.value(); //this is the value with the command removed
    //now restore everything
    $.address.value(currentAddressValue);
    $.address.history(addressHistoryState);
    $.address.autoUpdate(addressAutoUpdateState);
    return returnValue;
}


/**
 * This will remove a command from the url. It seems the open commands are what usually need to be removed.
 * Given open=id this will remove open=id
 */
function removeCommandFromURL(command, id, dontUpdateAddress) {
    $.address.autoUpdate(false);
    $.address.history(false);
    var val = $.address.value();
    // expands to something like: /open=FriendsProvidentMeeting(&|$)/
    var regexstring = command + "=" + id + "(&|$)";
    // need to set up a regex looking for either another "&" at end of id or an end of line.
    var regexp = new RegExp(regexstring, 'g');
    if (regexp.test(val)) {
       /* val = val.replace(regexp, ""); //TODO: What about spaces?
        // in case I didn't remove it with the last line
        val = val.replace(command + "=" + id, "");
        */
        // 3033 solve bug on remove item from url
        var element = $.address.value().split('&');
        for(var i=0; i<element.length; i++){
            if(element[i]==command + "=" + id){
                element[i] = '';
            }
        }
        val = element.join('&');
        val = val.replace("&&", "&");
        val = val.replace("&=", "");

        val = val.replace(/&$/, "");
        if (val !== undefined) {
            val = verifyQuestionMarkInURL(val);
            $.address.value(val);
            // this.oldAddressValue = "";
            // TODO: Get the title of the last open item if any in URL
            // $.address.title(this.oldTitle);
            dontUpdateAddress !== undefined && dontUpdateAddress ? {} : updateAddress(); //default is to call updateAddress()
        }
    }
    PreviousUrlAddress = $.address.value();
}

/**
 * See reportMyState(). This gets states for all elements found in idGroupObject and record their state so
 * that they can be restored again via restoreStatesOfAllWrappedElements() after hovering off
 *
 * @param savedStateOfWrappedElements
 */
function getStatesOfAllWrappedElements(savedStateOfWrappedElements) {
    var x;
    for (x in idGroupObject) {
        var state = idGroupObject[x].reportMyState();
        savedStateOfWrappedElements.push(state);
    }
}

function restoreStatesOfAllWrappedElements(savedStateOfWrappedElements) {
    var x, i = 0;
    if (savedStateOfWrappedElements) {
        for (x in idGroupObject) {
            //savedStateOfWrappedElements should have been populated in same order as the stuff in idGroupObject()
            idGroupObject[x] !== undefined && savedStateOfWrappedElements[i] !== undefined ? idGroupObject[x].restorMyState(savedStateOfWrappedElements[i]) : {};
            i++;
        }
    }
}

/**
 * This will take in a string from getDatabase(this.uno, "onURL") or offURL, etc and take out any junk like
 * amp; or ? and return the result
 *
 * @param onURL
 */
function fixUpOnURLetc(onURL) {
    var val;

    val = onURL.length ? onURL.replace(/&amp;/g, '&') : null;

    // get rid of question mark in rrr val
    val = val !== null ? val.replace(/\/\?/g, '') : null;
    val = val !== null ? val.replace(/\?/g, '') : null;
    return val;
}

/**
 * Mouse Interactivity – (works very much like it does now - except in how triggerHandler-groups work) A
 * hovering on/off a UNO’s ooo-group is equivalent to an open/close command. A click open/close is a
 * ‘persistent’ open/close command: • hover-on ooo-group = temporary open: hide ooo, show vvv and all of vvv’s
 * other siblings, show toolip. The ooo-group, although hidden, retains mouse control i.e. we're waiting for 2
 * possible mouse events: a hover-off the ooo, or a click on the ooo (the ccc-group will not get mouse control
 * unless a permanent click-open happens). • hover-off ooo-group = persistent close: hide vvv, show ooo,
 * tooltip off. Note that the UNO can contain other stuff: other objects, sss-groups – but its only the
 * ooo-group that responds to mouse events (only in a really simple object with loose artwork and no other
 * groups would it be right to to talk about hovering on the (whole) UNO.) • Hover-on ccc-group = temporary
 * close: hide vvv, show ooo, show toolip. The ccc-group, although hidden, retains mouse control i.e. we’re
 * waiting for 2 possible mouse events: a hover-off the ccc, or a click on the ccc (the ooo-group will not get
 * mouse control unless a permanent click-close happens). • Hover-off ccc-group = persistent-open: hide ooo,
 * show vvv, tooltip off. • click-on ooo-group = persistent open: hide ooo, show vvv, transfer mouse control
 * to the ccc-group, turn off tooltip (for bit - then show again (if still hovering on)), update URL. •
 * click-on ccc-group = persistent close: hide vvv, show ooo, transfer mouse control to the ooo-group,
 * turn-off tooltip (then wait a bit before allowing a hover-on) update URL. if in open state, ooo is not
 * visible and has no pointer events if in closed state, ooo is visible and has pointer events When site
 * initially starts, if something is turned on, then it is not in open state, but really a closed state.
 */

/**
 * There are various global variables that we want to get the value of. This will see if
 * it is defined and return it or return null
 **/
function getGlobal(globalVar) {

    switch (globalVar) {
        //stuff in the json file with global values
        case "hoverAction":
        case "hoverOpenClose":
            //we are defaulting to this being both, which means when user hovers over an object on the map, it will toggle between open and close of that object.
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                //true and false are for the few days of legacy code.
                if (idiagramSvg.designerPrefs[globalVar] === true) {
                    idiagramSvg.designerPrefs[globalVar] = "both";
                } else if (idiagramSvg.designerPrefs[globalVar] === false) {
                    idiagramSvg.designerPrefs[globalVar] = "none";
                }
                return idiagramSvg.designerPrefs[globalVar];
            } else return "both"; //default

        case "gutterWidth":
            if (idiagramSvg.designerPrefs.hasOwnProperty("gutterWidth")) /* legacy option name */ {
                return idiagramSvg.designerPrefs["gutterWidth"];
            } else return 100; //default
        case "gutterHeight":
            if (idiagramSvg.designerPrefs.hasOwnProperty("gutterHeight")) /* legacy option name */ {
                return idiagramSvg.designerPrefs["gutterHeight"];
            } else return 100; //default
        case "folder":
        case "svgFile":
        case "helpLink":
        case "segmentsFile":
        case "masterDB":
        case "overrideDB":
        case "minZoom":
        case "maxZoom":
        case "zoomSensitivity":
        case "tweenDuration":
        case "defaultURL":
        case "mapForm":
        case "formDB":
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                return idiagramSvg.designerPrefs[globalVar];
            }
        case "clickAction":
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                return idiagramSvg.designerPrefs[globalVar];
            } else return "both"; //defaults to true
        case "showDelay":
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                return idiagramSvg.designerPrefs[globalVar];
            } else return 0; //defaults to true
        case "hideDelay":
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                return idiagramSvg.designerPrefs[globalVar];
            } else return 0; //defaults to true
        case "gotozRatio":
            if (idiagramSvg.designerPrefs.hasOwnProperty(globalVar)) {
                return idiagramSvg.designerPrefs[globalVar];
            } else return 0.833333; //defaults to 0.833333
        //stuff NOT in that json file
        case "normalizeMapPane":
        case "processCommandsInURL":
        case "getURLParameterList":
        case "zoomTiger":
        case "isClass":
        case "tweenDuration":
        case "updateAddress":
        case "addZeroOpacity":
        case "removeZeroOpacity":
        case "messageServer":
        case "isKeyInParameterList":
        case "processAddress":
        case "mapEditorTemplate":
        case "database":
        case "dbEditing":
            if (idiagramSvg.hasOwnProperty(globalVar)) {
                return idiagramSvg[globalVar];
            }
        default:
            return null;
    }
}

var idiagramSvg = {
    normalizeMapPane: normalizeMapPane,
    processCommandsInURL: processCommandsInURL,
    getURLParameterList: getURLParameterList,
    zoomTiger: zoomTiger,
    isClass: isClass,
    tweenDuration: tweenDuration, //used in svg-pan-zoom for the tween duration
    traceTime: traceTime, // used in controlmenu.js
    hltDuration: hltDuration,
    hltOpacity: hltOpacity,
    bw: bw,
    updateAddress: updateAddress,
    addZeroOpacity: addZeroOpacity,
    removeZeroOpacity: removeZeroOpacity,
    messageServer: false, //true if this instance is controlling other browsers
    isKeyInParameterList: isKeyInParameterList,
    processAddress: processAddress,
    mapEditorTemplate: null,
    database: database,
    designerPrefs: designerPrefs,
    dbEditing: false,
    getDatabase: getDatabase,
    svgness: svgness,
    idGroupObject: idGroupObject,
    fixUpOnURLetc: fixUpOnURLetc,
    converter: converter,
    gClicked: gClicked,
    elClicked: elClicked,
    removeCommandFromURL: removeCommandFromURL,
    addCommandToURL: addCommandToURL,
    getGlobal: getGlobal,
    Panning: Panning,
    populateInfoPane: populateInfoPane,
    checkLocked: checkLocked,
    PreviousUrlAddress: PreviousUrlAddress,
    verifyQuestionMarkInURL: verifyQuestionMarkInURL,
    getStatesOfAllWrappedElements: getStatesOfAllWrappedElements,
    restoreStatesOfAllWrappedElements: restoreStatesOfAllWrappedElements,
    getSelectorAndResultArray: getSelectorAndResultArray,
    reloadZoomTiger: reloadZoomTiger,
    zoomRatio: zoomRatio,
    removeDupCommandValFromUrl: removeDupCommandValFromUrl,
    options: {
        onResetClick: onResetClick
    },
    showHelp: showHelp,
    zoomAll: zoomAll,
    processCommandsAndAddToURL: processCommandsAndAddToURL,
    doTweeningInSvgPanZoom: doTweeningInSvgPanZoom,
    stripId: stripId,
    masterForage: masterForage,
    lastUpdatedDb: lastUpdatedDb,
    flagDelay : flagDelay
};
function replaceAll(string, search, replace) {
  return string.split(search).join(replace);
}
module.exports = idiagramSvg;
